// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as CoursesReview__ReviewChecklistResult from "./CoursesReview__ReviewChecklistResult.bs.js";

function title(t) {
  return t.title;
}

function results(t) {
  return t.results;
}

function make(title, results) {
  return {
          title: title,
          results: results
        };
}

function makeFromJs(data) {
  return data.map(function (rc) {
              return {
                      title: rc.title,
                      results: CoursesReview__ReviewChecklistResult.makeFromJs(rc.result)
                    };
            });
}

function empty(param) {
  return [{
            title: "",
            results: [CoursesReview__ReviewChecklistResult.empty(undefined)]
          }];
}

function emptyTemplate(param) {
  return [{
            title: "Default checklist",
            results: CoursesReview__ReviewChecklistResult.emptyTemplate(undefined)
          }];
}

function updateTitle(title, t) {
  return {
          title: title,
          results: t.results
        };
}

function updateChecklist(results, t) {
  return {
          title: t.title,
          results: results
        };
}

function replace(t, itemIndex, results) {
  return results.map(function (item, index) {
              if (index === itemIndex) {
                return t;
              } else {
                return item;
              }
            });
}

function appendEmptyChecklistItem(t) {
  return {
          title: t.title,
          results: t.results.concat([CoursesReview__ReviewChecklistResult.empty(undefined)])
        };
}

function moveResultItemUp(index, t) {
  return {
          title: t.title,
          results: ArrayUtils.swapUp(index, t.results)
        };
}

function moveResultItemDown(index, t) {
  return {
          title: t.title,
          results: ArrayUtils.swapDown(index, t.results)
        };
}

function deleteResultItem(index, t) {
  return {
          title: t.title,
          results: t.results.filter(function (_el, i) {
                return i !== index;
              })
        };
}

function trim(t) {
  return {
          title: $$String.trim(t.title),
          results: t.results.map(CoursesReview__ReviewChecklistResult.trim)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "title",
                t.title
              ],
              tl: {
                hd: [
                  "result",
                  Json_encode.array(CoursesReview__ReviewChecklistResult.encode, t.results)
                ],
                tl: /* [] */0
              }
            });
}

function encodeArray(checklist) {
  return Json_encode.array(encode, checklist);
}

export {
  title ,
  results ,
  make ,
  makeFromJs ,
  empty ,
  emptyTemplate ,
  updateTitle ,
  updateChecklist ,
  replace ,
  appendEmptyChecklistItem ,
  moveResultItemUp ,
  moveResultItemDown ,
  deleteResultItem ,
  trim ,
  encode ,
  encodeArray ,
}
/* ArrayUtils Not a pure module */
