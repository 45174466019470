// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../shared/types/User.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";

function make(id, note, createdAt, author) {
  return {
          id: id,
          author: author,
          note: note,
          createdAt: createdAt
        };
}

function id(t) {
  return t.id;
}

function note(t) {
  return t.note;
}

function createdAt(t) {
  return t.createdAt;
}

function author(t) {
  return t.author;
}

function noteOn(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function sort(notes) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return DateFns.differenceInSeconds(y.createdAt, x.createdAt);
              }), notes);
}

function makeFromJs(note) {
  return make(note.id, note.note, DateFns.decodeISO(note.createdAt), OptionUtils.map(User.makeFromJs, note.author));
}

export {
  make ,
  id ,
  note ,
  createdAt ,
  author ,
  noteOn ,
  sort ,
  makeFromJs ,
}
/* User Not a pure module */
