// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as SubmissionChecklistItem from "../types/SubmissionChecklistItem.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.SubmissionChecklistItemShow";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function kindIconClasses(result) {
  switch (result.TAG | 0) {
    case /* ShortText */0 :
        return "if i-short-text-light text-base md:text-lg text-gray-800 mt-px";
    case /* LongText */1 :
        return "if i-long-text-light text-base md:text-lg text-gray-800 mt-px";
    case /* Link */2 :
        return "if i-link-light text-base md:text-lg text-gray-800 mt-px";
    case /* MultiChoice */4 :
        return "if i-check-circle-alt-light text-base md:text-lg text-gray-800 mt-px";
    case /* Files */3 :
    case /* AudioRecord */5 :
        return "if i-file-light text-base md:text-lg text-gray-800 mt-px";
    
  }
}

function showFiles(files) {
  return React.createElement("div", {
              className: "flex flex-wrap"
            }, files.map(function (file) {
                  return React.createElement("a", {
                              key: "file-" + SubmissionChecklistItem.fileUrl(file),
                              className: "mt-1 me-3 flex border overflow-hidden rounded hover:shadow-md border-red-300 bg-white text-red-600 hover:border-red-500 hover:text-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
                              href: SubmissionChecklistItem.fileUrl(file),
                              target: "_blank"
                            }, React.createElement("span", {
                                  className: "course-show-attachments__attachment-title rounded text-xs font-semibold inline-block whitespace-nowrap truncate w-32 md:w-42 h-full px-3 py-2 leading-loose"
                                }, SubmissionChecklistItem.fileName(file)), React.createElement("span", {
                                  className: "flex w-10 justify-center items-center p-2 bg-red-600 text-white"
                                }, React.createElement(PfIcon.make, {
                                      className: "if i-download-regular"
                                    })));
                }));
}

function showlink(link) {
  return React.createElement("a", {
              className: "max-w-fc mt-1 me-3 flex border overflow-hidden rounded hover:shadow-md border-blue-400 bg-white text-blue-700 hover:border-blue-600 hover:text-blue-800 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500",
              href: link,
              target: "_blank"
            }, React.createElement("span", {
                  className: "course-show-attachments__attachment-title rounded text-xs font-semibold inline-block whitespace-nowrap truncate w-32 md:w-42 h-full px-3 py-2 leading-loose"
                }, link), React.createElement("span", {
                  className: "flex w-10 justify-center items-center p-2 bg-blue-700 text-white"
                }, React.createElement(PfIcon.make, {
                      className: "if i-external-link-regular rtl:-rotate-90"
                    })));
}

function statusIcon(updateChecklistCB, status) {
  if (updateChecklistCB !== undefined) {
    return null;
  }
  switch (status) {
    case /* Passed */0 :
        return React.createElement("div", {
                    className: "flex items-center gap-2 text-xs bg-green-100 px-1 py-px mt-1"
                  }, React.createElement(PfIcon.make, {
                        className: "if i-check-square-solid text-green-500 text-base bg-white"
                      }), React.createElement("p", undefined, tr(undefined, undefined, "correct")));
    case /* Failed */1 :
        return React.createElement("div", {
                    className: "flex items-center text-red-500 gap-2 text-xs px-1 py-px mt-px"
                  }, React.createElement(PfIcon.make, {
                        className: "if i-times-square-solid block  text-base bg-white"
                      }), React.createElement("p", undefined, tr(undefined, undefined, "incorrect")));
    case /* NoAnswer */2 :
        return null;
    
  }
}

function showStatus(status) {
  switch (status) {
    case /* Passed */0 :
        return React.createElement("div", {
                    className: "bg-green-200 rounded px-1 py-px text-green-800 text-tiny"
                  }, tr(undefined, undefined, "correct"));
    case /* Failed */1 :
        return React.createElement("div", {
                    className: "bg-red-200 rounded px-1 py-px text-red-800 text-tiny"
                  }, "Incorrect");
    case /* NoAnswer */2 :
        return null;
    
  }
}

function showChoices(choices) {
  return choices.map(function (choice) {
              return React.createElement("p", {
                          className: "text-sm text-gray-800"
                        }, "- " + choice);
            });
}

function statusButtonSelectedClasses(status, currentStatus) {
  var tmp;
  switch (currentStatus) {
    case /* Passed */0 :
        tmp = status !== 0 ? "bg-white" : "bg-green-100 hover:bg-green-100 text-green-800 hover:text-green-800 border-green-500 z-10";
        break;
    case /* Failed */1 :
        tmp = status !== 1 ? "bg-white" : "bg-red-100 hover:bg-red-100 text-red-700 hover:text-red-700 border-red-500 z-10";
        break;
    case /* NoAnswer */2 :
        tmp = "bg-white";
        break;
    
  }
  return "inline-flex items-center cursor-pointer leading-tight font-semibold inline-block text-xs relative hover:bg-gray-50 hover:text-gray-600 " + tmp;
}

function statusButtonIcon(bool) {
  if (bool) {
    return "if i-times-square-solid text-base if-fw";
  } else {
    return "if i-square-regular text-base if-fw text-gray-500";
  }
}

function statusButtonOnClick(bool, callback, checklist, index, _event) {
  if (bool) {
    return Curry._1(callback, SubmissionChecklistItem.makeNoAnswer(index, checklist));
  } else {
    return Curry._1(callback, SubmissionChecklistItem.makeFailed(index, checklist));
  }
}

function statusButton(index, status, callback, checklist) {
  var partial_arg = status === /* Failed */1;
  return React.createElement("div", {
              className: "mt-2"
            }, React.createElement("button", {
                  className: "border border-gray-500 rounded focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 " + statusButtonSelectedClasses(/* Failed */1, status),
                  onClick: (function (param) {
                      return statusButtonOnClick(partial_arg, callback, checklist, index, param);
                    })
                }, React.createElement("span", {
                      className: "w-8 p-2 border-e border-gray-500 flex items-center justify-center"
                    }, React.createElement(PfIcon.make, {
                          className: status === /* Failed */1 ? "if i-times-square-solid text-base if-fw" : "if i-square-regular text-base if-fw text-gray-500"
                        })), React.createElement("span", {
                      className: "p-2"
                    }, status === /* Failed */1 ? tr(undefined, undefined, "mark_correct") : tr(undefined, undefined, "mark_incorrect"))));
}

function cardBodyClasses(pending) {
  return "ps-7 md:ps-8 " + (
          pending ? "" : "rounded-b"
        );
}

function SubmissionChecklistItemShow(Props) {
  var index = Props.index;
  var checklistItem = Props.checklistItem;
  var updateChecklistCB = Props.updateChecklistCB;
  var checklist = Props.checklist;
  var status = SubmissionChecklistItem.status(checklistItem);
  var text = SubmissionChecklistItem.result(checklistItem);
  var tmp;
  switch (text.TAG | 0) {
    case /* ShortText */0 :
        tmp = React.createElement("div", undefined, text._0);
        break;
    case /* LongText */1 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(MarkdownBlock.make, {
                  markdown: text._0,
                  profile: /* Permissive */0
                }), React.createElement(Spread.make, {
                  props: {
                    "data-controller": "safe-assign",
                    "data-safe-assign-title-value": SubmissionChecklistItem.title(checklistItem)
                  },
                  children: React.createElement("div", undefined)
                }));
        break;
    case /* Link */2 :
        tmp = showlink(text._0);
        break;
    case /* Files */3 :
        tmp = showFiles(text._0);
        break;
    case /* MultiChoice */4 :
        tmp = React.createElement("div", {
              className: "space-y-2"
            }, showChoices(text._0));
        break;
    case /* AudioRecord */5 :
        tmp = React.createElement("audio", {
              controls: true,
              src: text._0.url
            });
        break;
    
  }
  return React.createElement("div", {
              "aria-label": SubmissionChecklistItem.title(checklistItem)
            }, React.createElement("div", {
                  className: "text-sm font-medium"
                }, React.createElement("div", {
                      className: "flex items-start space-x-2"
                    }, React.createElement("div", {
                          className: "flex items-start w-full gap-3"
                        }, React.createElement(PfIcon.make, {
                              className: kindIconClasses(SubmissionChecklistItem.result(checklistItem))
                            }), React.createElement("div", {
                              className: "overflow-x-auto"
                            }, React.createElement(MarkdownBlock.make, {
                                  markdown: SubmissionChecklistItem.title(checklistItem),
                                  className: "block tracking-wide",
                                  profile: /* Permissive */0
                                }))), statusIcon(updateChecklistCB, status))), React.createElement("div", {
                  className: "ps-7 mt-2 text-sm"
                }, React.createElement("div", undefined, tmp), updateChecklistCB !== undefined ? statusButton(index, status, updateChecklistCB, checklist) : null));
}

var ChecklistItem;

var make = SubmissionChecklistItemShow;

export {
  ChecklistItem ,
  str ,
  tr ,
  kindIconClasses ,
  showFiles ,
  showlink ,
  statusIcon ,
  showStatus ,
  showChoices ,
  statusButtonSelectedClasses ,
  statusButtonIcon ,
  statusButtonOnClick ,
  statusButton ,
  cardBodyClasses ,
  make ,
}
/* I18n Not a pure module */
