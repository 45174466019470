// Generated by ReScript, PLEASE EDIT WITH CARE


function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function makeFromJs(jsObject) {
  return {
          id: jsObject.id,
          name: jsObject.name
        };
}

export {
  id ,
  name ,
  makeFromJs ,
}
/* No side effect */
