// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as IssuedCertificate from "../shared/types/IssuedCertificate.bs.js";
import * as VerifyCertificate__Root from "../issued_certificates/VerifyCertificate__Root.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("issuedCertificate", IssuedCertificate.decode, json),
          Json_decode.field("verifyImageUrl", Json_decode.string, json),
          Json_decode.field("currentUser", Json_decode.bool, json)
        ];
}

Psj.match(undefined, "issued_certificates#verify", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(VerifyCertificate__Root.make, {
                    issuedCertificate: match[0],
                    verifyImageUrl: match[1],
                    currentUser: match[2]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
}
/*  Not a pure module */
