// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var InvalidSubmissionReportCompletionStatus = /* @__PURE__ */Caml_exceptions.create("CoursesReview__SubmissionReport.InvalidSubmissionReportCompletionStatus");

function decodeStatus(status) {
  if (status === "queued") {
    return /* Queued */0;
  } else if (status === "error") {
    return /* Error */4;
  } else if (status === "failure") {
    return /* Failure */3;
  } else if (status === "success") {
    return /* Success */2;
  } else {
    return /* InProgress */1;
  }
}

function makeFromJS(object) {
  return {
          id: object.id,
          status: decodeStatus(object.status),
          startedAt: Belt_Option.map(object.startedAt, DateFns.decodeISO),
          completedAt: Belt_Option.map(object.completedAt, DateFns.decodeISO),
          report: Caml_option.nullable_to_opt(object.report),
          queuedAt: DateFns.decodeISO(object.queuedAt),
          reporter: object.reporter,
          heading: Caml_option.nullable_to_opt(object.heading),
          targetUrl: object.targetUrl
        };
}

function id(t) {
  return t.id;
}

function status(t) {
  return t.status;
}

function report(t) {
  return t.report;
}

function startedAt(t) {
  return t.startedAt;
}

function queuedAt(t) {
  return t.queuedAt;
}

function completedAt(t) {
  return t.completedAt;
}

function reporter(t) {
  return t.reporter;
}

function heading(t) {
  return t.heading;
}

function targetUrl(t) {
  return t.targetUrl;
}

export {
  InvalidSubmissionReportCompletionStatus ,
  decodeStatus ,
  makeFromJS ,
  id ,
  status ,
  report ,
  startedAt ,
  queuedAt ,
  completedAt ,
  reporter ,
  heading ,
  targetUrl ,
}
/* DateFns Not a pure module */
