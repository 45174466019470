// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function title(t) {
  return t.title;
}

function affiliation(t) {
  return t.affiliation;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function about(t) {
  return t.about;
}

function connectLink(t) {
  return t.connectLink;
}

function courseIds(t) {
  return t.courseIds;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          affiliation: Json_decode.optional((function (param) {
                  return Json_decode.field("affiliation", Json_decode.string, param);
                }), json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          about: Json_decode.optional((function (param) {
                  return Json_decode.field("about", Json_decode.string, param);
                }), json),
          connectLink: Json_decode.optional((function (param) {
                  return Json_decode.field("connectLink", Json_decode.string, param);
                }), json),
          courseIds: Json_decode.field("courseIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        };
}

function fullTitle(t) {
  var affiliation = t.affiliation;
  if (affiliation !== undefined) {
    return t.title + (", " + affiliation);
  } else {
    return t.title;
  }
}

export {
  id ,
  name ,
  title ,
  affiliation ,
  avatarUrl ,
  about ,
  connectLink ,
  courseIds ,
  decode ,
  fullTitle ,
}
/* No side effect */
