// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as GradeLabel from "../../../shared/types/GradeLabel.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";
import * as CoursesReview__Student from "./CoursesReview__Student.bs.js";
import * as CoursesReview__Reviewer from "./CoursesReview__Reviewer.bs.js";
import * as CoursesReview__SubmissionMeta from "./CoursesReview__SubmissionMeta.bs.js";
import * as CoursesReview__SubmissionReport from "./CoursesReview__SubmissionReport.bs.js";
import * as CoursesReview__OverlaySubmission from "./CoursesReview__OverlaySubmission.bs.js";
import * as CoursesReview__ReviewChecklistItem from "./CoursesReview__ReviewChecklistItem.bs.js";

function submission(t) {
  return t.submission;
}

function allSubmissions(t) {
  return t.allSubmissions;
}

function targetId(t) {
  return t.targetId;
}

function targetTitle(t) {
  return t.targetTitle;
}

function students(t) {
  return t.students;
}

function evaluationCriteria(t) {
  return t.evaluationCriteria;
}

function reviewChecklist(t) {
  return t.reviewChecklist;
}

function targetEvaluationCriteriaIds(t) {
  return t.targetEvaluationCriteriaIds;
}

function coaches(t) {
  return t.coaches;
}

function teamName(t) {
  return t.teamName;
}

function courseId(t) {
  return t.courseId;
}

function createdAt(t) {
  return t.createdAt;
}

function reviewer(t) {
  return t.reviewer;
}

function submissionReports(t) {
  return t.submissionReports;
}

function submissionReportPollTime(t) {
  return t.submissionReportPollTime;
}

function reviewable(t) {
  return t.reviewable;
}

function warning(t) {
  return t.warning;
}

function make(submission, allSubmissions, targetId, targetTitle, students, evaluationCriteria, reviewChecklist, targetEvaluationCriteriaIds, submissionReports, coaches, teamName, courseId, createdAt, reviewer, submissionReportPollTime, reviewable, warning) {
  return {
          submission: submission,
          createdAt: createdAt,
          allSubmissions: allSubmissions,
          targetId: targetId,
          targetTitle: targetTitle,
          students: students,
          submissionReports: submissionReports,
          evaluationCriteria: evaluationCriteria,
          reviewChecklist: reviewChecklist,
          targetEvaluationCriteriaIds: targetEvaluationCriteriaIds,
          coaches: coaches,
          teamName: teamName,
          courseId: courseId,
          reviewer: reviewer,
          submissionReportPollTime: submissionReportPollTime,
          reviewable: reviewable,
          warning: warning
        };
}

function decodeJs(details) {
  return make(CoursesReview__OverlaySubmission.makeFromJs(details.submission), ArrayUtils.copyAndSort((function (s1, s2) {
                    return DateFns.differenceInSeconds(CoursesReview__SubmissionMeta.createdAt(s2), CoursesReview__SubmissionMeta.createdAt(s1));
                  }), CoursesReview__SubmissionMeta.makeFromJs(details.allSubmissions)), details.targetId, details.targetTitle, details.students.map(CoursesReview__Student.makeFromJs), details.evaluationCriteria.map(function (ec) {
                  return EvaluationCriterion.make(ec.id, ec.name, ec.maxGrade, ec.gradeLabels.map(GradeLabel.makeFromJs));
                }), CoursesReview__ReviewChecklistItem.makeFromJs(details.reviewChecklist), details.targetEvaluationCriteriaIds, details.submissionReports.map(CoursesReview__SubmissionReport.makeFromJS), Js_array.map(UserProxy.makeFromJs, details.coaches), details.teamName, details.courseId, DateFns.decodeISO(details.createdAt), Belt_Option.map(details.reviewerDetails, CoursesReview__Reviewer.makeFromJs), details.submissionReportPollTime, details.reviewable, details.warning);
}

function updateMetaSubmission(submission) {
  return CoursesReview__SubmissionMeta.make(CoursesReview__OverlaySubmission.id(submission), CoursesReview__OverlaySubmission.createdAt(submission), CoursesReview__OverlaySubmission.passedAt(submission), CoursesReview__OverlaySubmission.evaluatedAt(submission), ArrayUtils.isNotEmpty(CoursesReview__OverlaySubmission.feedback(submission)), CoursesReview__OverlaySubmission.archivedAt(submission));
}

function updateOverlaySubmission(submission, t) {
  return {
          submission: submission,
          createdAt: t.createdAt,
          allSubmissions: Js_array.map((function (s) {
                  if (CoursesReview__SubmissionMeta.id(s) === CoursesReview__OverlaySubmission.id(submission)) {
                    return updateMetaSubmission(submission);
                  } else {
                    return s;
                  }
                }), t.allSubmissions),
          targetId: t.targetId,
          targetTitle: t.targetTitle,
          students: t.students,
          submissionReports: t.submissionReports,
          evaluationCriteria: t.evaluationCriteria,
          reviewChecklist: t.reviewChecklist,
          targetEvaluationCriteriaIds: t.targetEvaluationCriteriaIds,
          coaches: t.coaches,
          teamName: t.teamName,
          courseId: t.courseId,
          reviewer: t.reviewer,
          submissionReportPollTime: t.submissionReportPollTime,
          reviewable: t.reviewable,
          warning: t.warning
        };
}

function updateReviewChecklist(reviewChecklist, t) {
  return {
          submission: t.submission,
          createdAt: t.createdAt,
          allSubmissions: t.allSubmissions,
          targetId: t.targetId,
          targetTitle: t.targetTitle,
          students: t.students,
          submissionReports: t.submissionReports,
          evaluationCriteria: t.evaluationCriteria,
          reviewChecklist: reviewChecklist,
          targetEvaluationCriteriaIds: t.targetEvaluationCriteriaIds,
          coaches: t.coaches,
          teamName: t.teamName,
          courseId: t.courseId,
          reviewer: t.reviewer,
          submissionReportPollTime: t.submissionReportPollTime,
          reviewable: t.reviewable,
          warning: t.warning
        };
}

function updateReviewer(user, t) {
  return {
          submission: t.submission,
          createdAt: t.createdAt,
          allSubmissions: t.allSubmissions,
          targetId: t.targetId,
          targetTitle: t.targetTitle,
          students: t.students,
          submissionReports: t.submissionReports,
          evaluationCriteria: t.evaluationCriteria,
          reviewChecklist: t.reviewChecklist,
          targetEvaluationCriteriaIds: t.targetEvaluationCriteriaIds,
          coaches: t.coaches,
          teamName: t.teamName,
          courseId: t.courseId,
          reviewer: Belt_Option.map(user, CoursesReview__Reviewer.setReviewer),
          submissionReportPollTime: t.submissionReportPollTime,
          reviewable: t.reviewable,
          warning: t.warning
        };
}

function updateSubmissionReport(reports, t) {
  return {
          submission: t.submission,
          createdAt: t.createdAt,
          allSubmissions: t.allSubmissions,
          targetId: t.targetId,
          targetTitle: t.targetTitle,
          students: t.students,
          submissionReports: reports,
          evaluationCriteria: t.evaluationCriteria,
          reviewChecklist: t.reviewChecklist,
          targetEvaluationCriteriaIds: t.targetEvaluationCriteriaIds,
          coaches: t.coaches,
          teamName: t.teamName,
          courseId: t.courseId,
          reviewer: t.reviewer,
          submissionReportPollTime: t.submissionReportPollTime,
          reviewable: t.reviewable,
          warning: t.warning
        };
}

var OverlaySubmission;

var IndexSubmission;

var Student;

var ReviewChecklistItem;

var SubmissionMeta;

var Coach;

var Reviewer;

var SubmissionReport;

export {
  OverlaySubmission ,
  IndexSubmission ,
  Student ,
  ReviewChecklistItem ,
  SubmissionMeta ,
  Coach ,
  Reviewer ,
  SubmissionReport ,
  submission ,
  allSubmissions ,
  targetId ,
  targetTitle ,
  students ,
  evaluationCriteria ,
  reviewChecklist ,
  targetEvaluationCriteriaIds ,
  coaches ,
  teamName ,
  courseId ,
  createdAt ,
  reviewer ,
  submissionReports ,
  submissionReportPollTime ,
  reviewable ,
  warning ,
  make ,
  decodeJs ,
  updateMetaSubmission ,
  updateOverlaySubmission ,
  updateReviewChecklist ,
  updateReviewer ,
  updateSubmissionReport ,
}
/* DateFns Not a pure module */
