// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedProgressionBehavior = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Course.UnexpectedProgressionBehavior");

function ended(t) {
  return t.ended;
}

function id(t) {
  return t.id;
}

function certificateSerialNumber(t) {
  return t.certificateSerialNumber;
}

function progressionBehavior(t) {
  return t.progressionBehavior;
}

function progressionLimit(t) {
  var limit = t.progressionBehavior;
  if (limit) {
    return limit._0;
  } else {
    return 0;
  }
}

function decode(json) {
  var progressionLimit = Json_decode.field("progressionLimit", Json_decode.$$int, json);
  var progressionBehavior = progressionLimit !== 0 ? /* Limited */({
        _0: progressionLimit
      }) : /* Unlimited */0;
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          ended: Json_decode.field("ended", Json_decode.bool, json),
          certificateSerialNumber: Json_decode.optional((function (param) {
                  return Json_decode.field("certificateSerialNumber", Json_decode.string, param);
                }), json),
          progressionBehavior: progressionBehavior
        };
}

export {
  UnexpectedProgressionBehavior ,
  ended ,
  id ,
  certificateSerialNumber ,
  progressionBehavior ,
  progressionLimit ,
  decode ,
}
/* No side effect */
