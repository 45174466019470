// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesReview__Editor from "./CoursesReview__Editor.bs.js";
import * as CoursesReview__SubmissionMeta from "../types/CoursesReview__SubmissionMeta.bs.js";
import * as CoursesReview__SubmissionDetails from "../types/CoursesReview__SubmissionDetails.bs.js";

function str(prim) {
  return prim;
}

var Raw = {};

var query = "query SubmissionDetailsQuery($submissionId: ID!)  {\nsubmissionDetails(submissionId: $submissionId)  {\ntargetId  \ntargetTitle  \ncreatedAt  \nsubmissionReportPollTime  \nstudents  {\nid  \nname  \n}\n\nsubmissionReports  {\nid  \nreport  \nstatus  \nstartedAt  \ncompletedAt  \nqueuedAt  \nreporter  \nheading  \ntargetUrl  \n}\n\nevaluationCriteria  {\nid  \nname  \nmaxGrade  \ngradeLabels  {\ngrade  \nlabel  \n}\n\n}\n\nreviewChecklist  {\ntitle  \nresult  {\ntitle  \nfeedback  \n}\n\n}\n\ntargetEvaluationCriteriaIds  \nsubmission  {\nid  \nevaluatorName  \npassedAt  \ncreatedAt  \nevaluatedAt  \narchivedAt  \nfiles  {\nurl  \ntitle  \nid  \n}\n\ngrades  {\nevaluationCriterionId  \ngrade  \n}\n\nfeedback  {\nid  \ncoachName  \ncoachAvatarUrl  \ncoachTitle  \ncreatedAt  \nvalue  \n}\n\nchecklist  \n}\n\nallSubmissions  {\nid  \npassedAt  \ncreatedAt  \nevaluatedAt  \nfeedbackSent  \narchivedAt  \n}\n\ncoaches  {\n...UserProxyFragment   \n}\n\nteamName  \ncourseId  \nreviewable  \nwarning  \nreviewerDetails  {\nassignedAt  \nuser  {\n...UserProxyFragment   \n}\n\n}\n\n}\n\n}\n" + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.submissionDetails;
  var value$2 = value$1.students;
  var value$3 = value$1.submissionReports;
  var value$4 = value$1.evaluationCriteria;
  var value$5 = value$1.reviewChecklist;
  var value$6 = value$1.targetEvaluationCriteriaIds;
  var value$7 = value$1.submission;
  var value$8 = value$7.evaluatorName;
  var value$9 = value$7.passedAt;
  var value$10 = value$7.evaluatedAt;
  var value$11 = value$7.archivedAt;
  var value$12 = value$7.files;
  var value$13 = value$7.grades;
  var value$14 = value$7.feedback;
  var value$15 = value$1.allSubmissions;
  var value$16 = value$1.coaches;
  var value$17 = value$1.teamName;
  var value$18 = value$1.warning;
  var value$19 = value$1.reviewerDetails;
  var tmp;
  if (value$19 == null) {
    tmp = undefined;
  } else {
    var value$20 = value$19.user;
    tmp = {
      assignedAt: value$19.assignedAt,
      user: UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value$20)
    };
  }
  return {
          submissionDetails: {
            targetId: value$1.targetId,
            targetTitle: value$1.targetTitle,
            createdAt: value$1.createdAt,
            submissionReportPollTime: value$1.submissionReportPollTime,
            students: Js_array.map((function (value) {
                    return {
                            id: value.id,
                            name: value.name
                          };
                  }), value$2),
            submissionReports: Js_array.map((function (value) {
                    var value$1 = value.report;
                    var value$2 = value.status;
                    var tmp;
                    switch (value$2) {
                      case "error" :
                          tmp = "error";
                          break;
                      case "failure" :
                          tmp = "failure";
                          break;
                      case "in_progress" :
                          tmp = "in_progress";
                          break;
                      case "queued" :
                          tmp = "queued";
                          break;
                      case "success" :
                          tmp = "success";
                          break;
                      default:
                        tmp = {
                          NAME: "FutureAddedValue",
                          VAL: value$2
                        };
                    }
                    var value$3 = value.startedAt;
                    var value$4 = value.completedAt;
                    var value$5 = value.heading;
                    var value$6 = value.targetUrl;
                    return {
                            id: value.id,
                            report: !(value$1 == null) ? value$1 : undefined,
                            status: tmp,
                            startedAt: !(value$3 == null) ? Caml_option.some(value$3) : undefined,
                            completedAt: !(value$4 == null) ? Caml_option.some(value$4) : undefined,
                            queuedAt: value.queuedAt,
                            reporter: value.reporter,
                            heading: !(value$5 == null) ? value$5 : undefined,
                            targetUrl: !(value$6 == null) ? value$6 : undefined
                          };
                  }), value$3),
            evaluationCriteria: Js_array.map((function (value) {
                    var value$1 = value.gradeLabels;
                    return {
                            id: value.id,
                            name: value.name,
                            maxGrade: value.maxGrade,
                            gradeLabels: Js_array.map((function (value) {
                                    return {
                                            grade: value.grade,
                                            label: value.label
                                          };
                                  }), value$1)
                          };
                  }), value$4),
            reviewChecklist: Js_array.map((function (value) {
                    var value$1 = value.result;
                    return {
                            title: value.title,
                            result: Js_array.map((function (value) {
                                    var value$1 = value.feedback;
                                    return {
                                            title: value.title,
                                            feedback: !(value$1 == null) ? value$1 : undefined
                                          };
                                  }), value$1)
                          };
                  }), value$5),
            targetEvaluationCriteriaIds: Js_array.map((function (value) {
                    return value;
                  }), value$6),
            submission: {
              id: value$7.id,
              evaluatorName: !(value$8 == null) ? value$8 : undefined,
              passedAt: !(value$9 == null) ? Caml_option.some(value$9) : undefined,
              createdAt: value$7.createdAt,
              evaluatedAt: !(value$10 == null) ? Caml_option.some(value$10) : undefined,
              archivedAt: !(value$11 == null) ? Caml_option.some(value$11) : undefined,
              files: Js_array.map((function (value) {
                      return {
                              url: value.url,
                              title: value.title,
                              id: value.id
                            };
                    }), value$12),
              grades: Js_array.map((function (value) {
                      return {
                              evaluationCriterionId: value.evaluationCriterionId,
                              grade: value.grade
                            };
                    }), value$13),
              feedback: Js_array.map((function (value) {
                      var value$1 = value.coachName;
                      var value$2 = value.coachAvatarUrl;
                      return {
                              id: value.id,
                              coachName: !(value$1 == null) ? value$1 : undefined,
                              coachAvatarUrl: !(value$2 == null) ? value$2 : undefined,
                              coachTitle: value.coachTitle,
                              createdAt: value.createdAt,
                              value: value.value
                            };
                    }), value$14),
              checklist: value$7.checklist
            },
            allSubmissions: Js_array.map((function (value) {
                    var value$1 = value.passedAt;
                    var value$2 = value.evaluatedAt;
                    var value$3 = value.archivedAt;
                    return {
                            id: value.id,
                            passedAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                            createdAt: value.createdAt,
                            evaluatedAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                            feedbackSent: value.feedbackSent,
                            archivedAt: !(value$3 == null) ? Caml_option.some(value$3) : undefined
                          };
                  }), value$15),
            coaches: Js_array.map((function (value) {
                    return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
                  }), value$16),
            teamName: !(value$17 == null) ? value$17 : undefined,
            courseId: value$1.courseId,
            reviewable: value$1.reviewable,
            warning: !(value$18 == null) ? value$18 : undefined,
            reviewerDetails: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.submissionDetails;
  var value$2 = value$1.reviewerDetails;
  var reviewerDetails;
  if (value$2 !== undefined) {
    var value$3 = value$2.user;
    var user = UserProxy.Fragment.serialize(value$3);
    var value$4 = value$2.assignedAt;
    reviewerDetails = {
      assignedAt: value$4,
      user: user
    };
  } else {
    reviewerDetails = null;
  }
  var value$5 = value$1.warning;
  var warning = value$5 !== undefined ? value$5 : null;
  var value$6 = value$1.reviewable;
  var value$7 = value$1.courseId;
  var value$8 = value$1.teamName;
  var teamName = value$8 !== undefined ? value$8 : null;
  var value$9 = value$1.coaches;
  var coaches = Js_array.map((function (value) {
          return UserProxy.Fragment.serialize(value);
        }), value$9);
  var value$10 = value$1.allSubmissions;
  var allSubmissions = Js_array.map((function (value) {
          var value$1 = value.archivedAt;
          var archivedAt = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
          var value$2 = value.feedbackSent;
          var value$3 = value.evaluatedAt;
          var evaluatedAt = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
          var value$4 = value.createdAt;
          var value$5 = value.passedAt;
          var passedAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
          var value$6 = value.id;
          return {
                  id: value$6,
                  passedAt: passedAt,
                  createdAt: value$4,
                  evaluatedAt: evaluatedAt,
                  feedbackSent: value$2,
                  archivedAt: archivedAt
                };
        }), value$10);
  var value$11 = value$1.submission;
  var value$12 = value$11.checklist;
  var value$13 = value$11.feedback;
  var feedback = Js_array.map((function (value) {
          var value$1 = value.value;
          var value$2 = value.createdAt;
          var value$3 = value.coachTitle;
          var value$4 = value.coachAvatarUrl;
          var coachAvatarUrl = value$4 !== undefined ? value$4 : null;
          var value$5 = value.coachName;
          var coachName = value$5 !== undefined ? value$5 : null;
          var value$6 = value.id;
          return {
                  id: value$6,
                  coachName: coachName,
                  coachAvatarUrl: coachAvatarUrl,
                  coachTitle: value$3,
                  createdAt: value$2,
                  value: value$1
                };
        }), value$13);
  var value$14 = value$11.grades;
  var grades = Js_array.map((function (value) {
          var value$1 = value.grade;
          var value$2 = value.evaluationCriterionId;
          return {
                  evaluationCriterionId: value$2,
                  grade: value$1
                };
        }), value$14);
  var value$15 = value$11.files;
  var files = Js_array.map((function (value) {
          var value$1 = value.id;
          var value$2 = value.title;
          var value$3 = value.url;
          return {
                  url: value$3,
                  title: value$2,
                  id: value$1
                };
        }), value$15);
  var value$16 = value$11.archivedAt;
  var archivedAt = value$16 !== undefined ? Caml_option.valFromOption(value$16) : null;
  var value$17 = value$11.evaluatedAt;
  var evaluatedAt = value$17 !== undefined ? Caml_option.valFromOption(value$17) : null;
  var value$18 = value$11.createdAt;
  var value$19 = value$11.passedAt;
  var passedAt = value$19 !== undefined ? Caml_option.valFromOption(value$19) : null;
  var value$20 = value$11.evaluatorName;
  var evaluatorName = value$20 !== undefined ? value$20 : null;
  var value$21 = value$11.id;
  var submission = {
    id: value$21,
    evaluatorName: evaluatorName,
    passedAt: passedAt,
    createdAt: value$18,
    evaluatedAt: evaluatedAt,
    archivedAt: archivedAt,
    files: files,
    grades: grades,
    feedback: feedback,
    checklist: value$12
  };
  var value$22 = value$1.targetEvaluationCriteriaIds;
  var targetEvaluationCriteriaIds = Js_array.map((function (value) {
          return value;
        }), value$22);
  var value$23 = value$1.reviewChecklist;
  var reviewChecklist = Js_array.map((function (value) {
          var value$1 = value.result;
          var result = Js_array.map((function (value) {
                  var value$1 = value.feedback;
                  var feedback = value$1 !== undefined ? value$1 : null;
                  var value$2 = value.title;
                  return {
                          title: value$2,
                          feedback: feedback
                        };
                }), value$1);
          var value$2 = value.title;
          return {
                  title: value$2,
                  result: result
                };
        }), value$23);
  var value$24 = value$1.evaluationCriteria;
  var evaluationCriteria = Js_array.map((function (value) {
          var value$1 = value.gradeLabels;
          var gradeLabels = Js_array.map((function (value) {
                  var value$1 = value.label;
                  var value$2 = value.grade;
                  return {
                          grade: value$2,
                          label: value$1
                        };
                }), value$1);
          var value$2 = value.maxGrade;
          var value$3 = value.name;
          var value$4 = value.id;
          return {
                  id: value$4,
                  name: value$3,
                  maxGrade: value$2,
                  gradeLabels: gradeLabels
                };
        }), value$24);
  var value$25 = value$1.submissionReports;
  var submissionReports = Js_array.map((function (value) {
          var value$1 = value.targetUrl;
          var targetUrl = value$1 !== undefined ? value$1 : null;
          var value$2 = value.heading;
          var heading = value$2 !== undefined ? value$2 : null;
          var value$3 = value.reporter;
          var value$4 = value.queuedAt;
          var value$5 = value.completedAt;
          var completedAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
          var value$6 = value.startedAt;
          var startedAt = value$6 !== undefined ? Caml_option.valFromOption(value$6) : null;
          var value$7 = value.status;
          var status = typeof value$7 === "object" ? value$7.VAL : (
              value$7 === "queued" ? "queued" : (
                  value$7 === "error" ? "error" : (
                      value$7 === "failure" ? "failure" : (
                          value$7 === "success" ? "success" : "in_progress"
                        )
                    )
                )
            );
          var value$8 = value.report;
          var report = value$8 !== undefined ? value$8 : null;
          var value$9 = value.id;
          return {
                  id: value$9,
                  report: report,
                  status: status,
                  startedAt: startedAt,
                  completedAt: completedAt,
                  queuedAt: value$4,
                  reporter: value$3,
                  heading: heading,
                  targetUrl: targetUrl
                };
        }), value$25);
  var value$26 = value$1.students;
  var students = Js_array.map((function (value) {
          var value$1 = value.name;
          var value$2 = value.id;
          return {
                  id: value$2,
                  name: value$1
                };
        }), value$26);
  var value$27 = value$1.submissionReportPollTime;
  var value$28 = value$1.createdAt;
  var value$29 = value$1.targetTitle;
  var value$30 = value$1.targetId;
  var submissionDetails = {
    targetId: value$30,
    targetTitle: value$29,
    createdAt: value$28,
    submissionReportPollTime: value$27,
    students: students,
    submissionReports: submissionReports,
    evaluationCriteria: evaluationCriteria,
    reviewChecklist: reviewChecklist,
    targetEvaluationCriteriaIds: targetEvaluationCriteriaIds,
    submission: submission,
    allSubmissions: allSubmissions,
    coaches: coaches,
    teamName: teamName,
    courseId: value$7,
    reviewable: value$6,
    warning: warning,
    reviewerDetails: reviewerDetails
  };
  return {
          submissionDetails: submissionDetails
        };
}

function serializeVariables(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var SubmissionDetailsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var SubmissionDetailsQuery_Graphql_error = include.Graphql_error;

var SubmissionDetailsQuery_decodeNotification = include.decodeNotification;

var SubmissionDetailsQuery_decodeObject = include.decodeObject;

var SubmissionDetailsQuery_decodeNotifications = include.decodeNotifications;

var SubmissionDetailsQuery_decodeErrors = include.decodeErrors;

var SubmissionDetailsQuery_flashNotifications = include.flashNotifications;

var SubmissionDetailsQuery_sendQuery = include.sendQuery;

var SubmissionDetailsQuery_query = include.query;

var SubmissionDetailsQuery_fetch = include.$$fetch;

var SubmissionDetailsQuery = {
  SubmissionDetailsQuery_inner: SubmissionDetailsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: SubmissionDetailsQuery_Graphql_error,
  decodeNotification: SubmissionDetailsQuery_decodeNotification,
  decodeObject: SubmissionDetailsQuery_decodeObject,
  decodeNotifications: SubmissionDetailsQuery_decodeNotifications,
  decodeErrors: SubmissionDetailsQuery_decodeErrors,
  flashNotifications: SubmissionDetailsQuery_flashNotifications,
  sendQuery: SubmissionDetailsQuery_sendQuery,
  query: SubmissionDetailsQuery_query,
  $$fetch: SubmissionDetailsQuery_fetch,
  make: make
};

function getSubmissionDetails(submissionId, setState, param) {
  Curry._1(setState, (function (param) {
          return /* Loading */0;
        }));
  Js_promise.then_((function (response) {
          Curry._1(setState, (function (param) {
                  return /* Loaded */{
                          _0: CoursesReview__SubmissionDetails.decodeJs(response.submissionDetails)
                        };
                }));
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            submissionId: submissionId
          }));
}

function updateSubmission(setState, submissionDetails, overlaySubmission) {
  var newSubmissionDetails = CoursesReview__SubmissionDetails.updateOverlaySubmission(overlaySubmission, submissionDetails);
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: newSubmissionDetails
                      };
              }));
}

function currentSubmissionIndex(submissionId, allSubmissions) {
  return allSubmissions.length - Js_array.findIndex((function (s) {
                return CoursesReview__SubmissionMeta.id(s) === submissionId;
              }), allSubmissions) | 0;
}

function updateReviewChecklist(submissionDetails, setState, reviewChecklist) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateReviewChecklist(reviewChecklist, submissionDetails)
                      };
              }));
}

function updateReviewer(submissionDetails, setState, reviewer) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateReviewer(reviewer, submissionDetails)
                      };
              }));
}

function updateSubmissionReport(submissionDetails, setState, submissionReports) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateSubmissionReport(submissionReports, submissionDetails)
                      };
              }));
}

function CoursesReview__SubmissionsRoot(Props) {
  var submissionId = Props.submissionId;
  var currentUser = Props.currentUser;
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          return getSubmissionDetails(submissionId, setState, undefined);
        }), [submissionId]);
  var tmp;
  if (state) {
    var submissionDetails = state._0;
    tmp = React.createElement(CoursesReview__Editor.make, {
          overlaySubmission: CoursesReview__SubmissionDetails.submission(submissionDetails),
          teamSubmission: CoursesReview__SubmissionDetails.students(submissionDetails).length > 1,
          evaluationCriteria: CoursesReview__SubmissionDetails.evaluationCriteria(submissionDetails),
          reviewChecklist: CoursesReview__SubmissionDetails.reviewChecklist(submissionDetails),
          updateSubmissionCB: (function (param) {
              return updateSubmission(setState, submissionDetails, param);
            }),
          updateReviewChecklistCB: (function (param) {
              return updateReviewChecklist(submissionDetails, setState, param);
            }),
          targetId: CoursesReview__SubmissionDetails.targetId(submissionDetails),
          targetEvaluationCriteriaIds: CoursesReview__SubmissionDetails.targetEvaluationCriteriaIds(submissionDetails),
          currentUser: currentUser,
          number: currentSubmissionIndex(submissionId, CoursesReview__SubmissionDetails.allSubmissions(submissionDetails)),
          submissionDetails: submissionDetails,
          submissionId: submissionId,
          updateReviewerCB: (function (param) {
              return updateReviewer(submissionDetails, setState, param);
            }),
          submissionReports: CoursesReview__SubmissionDetails.submissionReports(submissionDetails),
          updateSubmissionReportCB: (function (param) {
              return updateSubmissionReport(submissionDetails, setState, param);
            }),
          submissionReportPollTime: CoursesReview__SubmissionDetails.submissionReportPollTime(submissionDetails)
        });
  } else {
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "bg-gray-50 md:px-4"
            }, React.createElement("div", {
                  className: "mx-auto"
                }, SkeletonLoading.card(undefined, undefined))), React.createElement("div", {
              className: "grid md:grid-cols-2 mt-10 border-t h-full"
            }, React.createElement("div", {
                  className: "md:px-4 bg-white"
                }, SkeletonLoading.heading(undefined), SkeletonLoading.multiple(3, SkeletonLoading.paragraph(undefined))), React.createElement("div", {
                  className: "md:px-4 bg-gray-50 border-s"
                }, SkeletonLoading.userDetails(undefined), SkeletonLoading.paragraph(undefined), SkeletonLoading.userDetails(undefined), SkeletonLoading.paragraph(undefined))));
  }
  return React.createElement("div", {
              className: "flex-1 md:flex md:flex-col md:overflow-hidden fixed z-[50] inset-0 overflow-y-auto bg-white"
            }, tmp);
}

var UserProxyFragment;

var make$1 = CoursesReview__SubmissionsRoot;

export {
  str ,
  UserProxyFragment ,
  SubmissionDetailsQuery ,
  getSubmissionDetails ,
  updateSubmission ,
  currentSubmissionIndex ,
  updateReviewChecklist ,
  updateReviewer ,
  updateSubmissionReport ,
  make$1 as make,
}
/* include Not a pure module */
