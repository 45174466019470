// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";

function make(submissions, filter, sortDirection) {
  return {
          submissions: submissions,
          filter: filter,
          sortDirection: sortDirection
        };
}

function makeFilter(status) {
  return {
          status: status
        };
}

function partiallyLoaded(submissions, filter, sortDirection, cursor) {
  return {
          TAG: /* PartiallyLoaded */0,
          _0: {
            submissions: submissions,
            filter: filter,
            sortDirection: sortDirection
          },
          _1: cursor
        };
}

function fullyLoaded(submissions, filter, sortDirection) {
  return {
          TAG: /* FullyLoaded */1,
          _0: {
            submissions: submissions,
            filter: filter,
            sortDirection: sortDirection
          }
        };
}

function filterEq(status, filter) {
  return Caml_obj.equal(filter.status, status);
}

function needsReloading(selectedStatus, sortDirection, t) {
  if (typeof t === "number") {
    return true;
  }
  var data = t._0;
  return !(Caml_obj.equal(data.filter.status, selectedStatus) && data.sortDirection === sortDirection);
}

export {
  make ,
  makeFilter ,
  partiallyLoaded ,
  fullyLoaded ,
  filterEq ,
  needsReloading ,
}
/* No side effect */
