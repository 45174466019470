// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function title(t) {
  return t.title;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          title: Caml_option.null_to_opt(Json_decode.field("title", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json))
        };
}

function avatar(t) {
  return Belt_Option.mapWithDefault(t.avatarUrl, React.createElement(Avatar.make, {
                  name: t.name,
                  className: "w-full h-full"
                }), (function (src) {
                return React.createElement("img", {
                            src: src
                          });
              }));
}

export {
  id ,
  name ,
  avatarUrl ,
  title ,
  decode ,
  avatar ,
}
/* react Not a pure module */
