// Generated by ReScript, PLEASE EDIT WITH CARE

import * as List from "../../../../../node_modules/rescript/lib/es6/list.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as GradeLabel from "../../../shared/types/GradeLabel.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";
import * as CoursesCurriculum__Grading from "../types/CoursesCurriculum__Grading.bs.js";

import "./CoursesCurriculum__GradeBar.css"
;

function str(prim) {
  return prim;
}

function gradeDescription(gradeLabels, grading) {
  var grade = CoursesCurriculum__Grading.grade(grading);
  return React.createElement("div", {
              className: "grade-bar__criterion-name"
            }, CoursesCurriculum__Grading.criterionName(grading), grade !== undefined ? React.createElement("span", undefined, ": ", React.createElement("span", {
                        className: "grade-bar__grade-label"
                      }, GradeLabel.labelFor(gradeLabels, grade))) : null);
}

function maxGrade(gradeLabels) {
  return String(GradeLabel.maxGrade(gradeLabels));
}

function gradePillClasses(gradeReceived, pillGrade, callBack) {
  var resultModifier = gradeReceived !== undefined && !Caml_obj.greaterthan(pillGrade, Caml_option.valFromOption(gradeReceived)) ? " grade-bar__grade-pill--completed" : "";
  var selectableModifier = callBack !== undefined ? " grade-bar__grade-pill--selectable-pass cursor-pointer" : "";
  return "grade-bar__grade-pill cursor-auto" + (resultModifier + selectableModifier);
}

function gradeBarHeader(grading, gradeLabels) {
  var grade = CoursesCurriculum__Grading.grade(grading);
  return React.createElement("div", {
              className: "grade-bar__header pb-1"
            }, gradeDescription(gradeLabels, grading), grade !== undefined ? React.createElement("div", {
                    className: "grade-bar__grade font-semibold"
                  }, String(grade) + ("/" + String(GradeLabel.maxGrade(gradeLabels)))) : null);
}

function handleClick(gradeSelectCB, grading, newGrade) {
  if (gradeSelectCB !== undefined) {
    return Curry._1(gradeSelectCB, CoursesCurriculum__Grading.updateGrade(newGrade, grading));
  }
  
}

function gradeBarPill(gradeLabel, grading, gradeSelectCB) {
  var myGrade = GradeLabel.grade(gradeLabel);
  return React.createElement("div", {
              key: String(myGrade),
              className: gradePillClasses(CoursesCurriculum__Grading.grade(grading), myGrade, gradeSelectCB),
              role: "button",
              title: GradeLabel.label(gradeLabel),
              onClick: (function (_event) {
                  handleClick(gradeSelectCB, grading, myGrade);
                })
            }, gradeSelectCB !== undefined ? String(myGrade) : null);
}

function gradeBarPanel(grading, gradeLabels, gradeSelectCB) {
  return React.createElement("div", {
              className: "grade-bar__track",
              role: "group"
            }, $$Array.of_list(List.map((function (gradeLabel) {
                        return gradeBarPill(gradeLabel, grading, gradeSelectCB);
                      }), gradeLabels)));
}

function CoursesCurriculum__GradeBar(Props) {
  var grading = Props.grading;
  var gradeSelectCB = Props.gradeSelectCB;
  var criterion = Props.criterion;
  var gradeLabels = $$Array.to_list(EvaluationCriterion.gradesAndLabels(criterion));
  return React.createElement("div", {
              className: "flex-column",
              role: "toolbar"
            }, gradeBarHeader(grading, gradeLabels), gradeBarPanel(grading, gradeLabels, gradeSelectCB));
}

var make = CoursesCurriculum__GradeBar;

export {
  str ,
  gradeDescription ,
  maxGrade ,
  gradePillClasses ,
  gradeBarHeader ,
  handleClick ,
  gradeBarPill ,
  gradeBarPanel ,
  make ,
}
/*  Not a pure module */
