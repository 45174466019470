// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function name(t) {
  return t.name;
}

function title(t) {
  return t.title;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json),
          avatarUrl: Json_decode.field("avatarUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

export {
  name ,
  title ,
  avatarUrl ,
  decode ,
}
/* No side effect */
