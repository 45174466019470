// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Js_string from "../../../../../node_modules/rescript/lib/es6/js_string.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";
import * as CoursesCurriculum__User from "../types/CoursesCurriculum__User.bs.js";
import * as SubmissionChecklistShow from "../../review/components/SubmissionChecklistShow.bs.js";
import * as CoursesCurriculum__Coach from "../types/CoursesCurriculum__Coach.bs.js";
import * as CoursesCurriculum__Grade from "../types/CoursesCurriculum__Grade.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Grading from "../types/CoursesCurriculum__Grading.bs.js";
import * as CoursesCurriculum__Feedback from "../types/CoursesCurriculum__Feedback.bs.js";
import * as CoursesCurriculum__GradeBar from "./CoursesCurriculum__GradeBar.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__UndoButton from "./CoursesCurriculum__UndoButton.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";
import * as CoursesCurriculum__SubmissionBuilder from "./CoursesCurriculum__SubmissionBuilder.bs.js";

import "./CoursesCurriculum__SubmissionsAndFeedback.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionsAndFeedback";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function gradeBar(evaluationCriteria, grade) {
  var criterion = Js_array.find((function (c) {
          return EvaluationCriterion.id(c) === CoursesCurriculum__Grade.evaluationCriterionId(grade);
        }), evaluationCriteria);
  if (criterion === undefined) {
    return null;
  }
  var criterionId = EvaluationCriterion.id(criterion);
  var criterionName = EvaluationCriterion.name(criterion);
  var gradeNumber = CoursesCurriculum__Grade.grade(grade);
  var grading = CoursesCurriculum__Grading.make(criterionId, criterionName, gradeNumber);
  return React.createElement("div", {
              key: String(gradeNumber),
              className: "mb-4"
            }, React.createElement(CoursesCurriculum__GradeBar.make, {
                  grading: grading,
                  criterion: criterion
                }));
}

function statusBar(color, text) {
  var textColor = "text-" + (color + "-500 ");
  var bgColor = "bg-" + (color + "-100 ");
  var icon = color === "green" ? React.createElement("span", undefined, React.createElement("i", {
              className: "fas fa-certificate fa-stack-2x"
            }), React.createElement("i", {
              className: "fas fa-check fa-stack-1x fa-inverse"
            })) : React.createElement("i", {
          className: "fas fa-exclamation-triangle text-3xl text-red-500 mx-1"
        });
  return React.createElement("div", {
              className: "font-semibold p-2 py-4 flex border-t w-full items-center justify-center " + (textColor + bgColor)
            }, React.createElement("span", {
                  className: "fa-stack text-lg me-1 " + textColor
                }, icon), text);
}

function submissionStatusIcon(passed) {
  var text = passed ? tr(undefined, undefined, "completed") : tr(undefined, undefined, "rejected");
  var color = passed ? "green" : "red";
  return React.createElement("div", {
              className: "max-w-fc"
            }, React.createElement("div", {
                  className: "flex justify-center border-2 rounded-lg border-" + (color + "-500 px-4 py-6")
                }, passed ? React.createElement("div", {
                        className: "fa-stack text-green-500 text-lg"
                      }, React.createElement("i", {
                            className: "fas fa-certificate fa-stack-2x"
                          }), React.createElement("i", {
                            className: "fas fa-check fa-stack-1x fa-inverse"
                          })) : React.createElement("i", {
                        className: "fas fa-exclamation-triangle text-3xl text-red-500 mx-1"
                      })), React.createElement("div", {
                  className: "text-center text-" + (color + "-500 font-bold mt-2")
                }, text));
}

function undoSubmissionCB(param) {
  window.location.reload();
}

function gradingSection(grades, evaluationCriteria, gradeBar, passed) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "w-full md:hidden"
                }, statusBar(passed ? "green" : "red", passed ? tr(undefined, undefined, "completed") : tr(undefined, undefined, "rejected"))), React.createElement("div", {
                  className: "bg-white flex border-t flex-wrap items-center py-4"
                }, React.createElement("div", {
                      className: "w-full md:w-1/2 shrink-0 justify-center hidden md:flex border-s px-6"
                    }, submissionStatusIcon(passed)), React.createElement("div", {
                      className: "w-full md:w-1/2 shrink-0 md:order-first px-4 md:px-6"
                    }, React.createElement("h5", {
                          className: "pb-1 border-b"
                        }, tr(undefined, undefined, "grading")), React.createElement("div", {
                          className: "mt-3"
                        }, Js_array.map(Curry.__1(gradeBar), CoursesCurriculum__Grade.sort(evaluationCriteria, grades))))));
}

function handleAddAnotherSubmission(setShowSubmissionForm, $$event) {
  $$event.preventDefault();
  return Curry._1(setShowSubmissionForm, (function (showSubmissionForm) {
                return !showSubmissionForm;
              }));
}

function submissions(target, targetStatus, targetDetails, evaluationCriteria, coaches, users) {
  var curriedGradeBar = function (param) {
    return gradeBar(evaluationCriteria, param);
  };
  var submissions$1 = CoursesCurriculum__TargetDetails.submissions(targetDetails);
  var totalSubmissions = submissions$1.length;
  var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails);
  var shouldRenderButton = Js_string.includes("seb", window.navigator.userAgent);
  return CoursesCurriculum__Submission.sort(submissions$1).map(function (submission, index) {
              var grades = CoursesCurriculum__TargetDetails.grades(CoursesCurriculum__Submission.id(submission), targetDetails);
              var match = CoursesCurriculum__TargetStatus.status(targetStatus);
              var match$1 = CoursesCurriculum__Submission.status(submission);
              var tmp;
              switch (match$1) {
                case /* MarkedAsComplete */0 :
                    tmp = statusBar("green", tr(undefined, undefined, "completed"));
                    break;
                case /* Pending */1 :
                    var match$2 = CoursesCurriculum__TargetStatus.status(targetStatus);
                    tmp = React.createElement("div", {
                          className: "bg-white p-3 md:px-6 md:py-4 flex border-t justify-between items-center w-full"
                        }, React.createElement("div", {
                              className: "flex items-center justify-center font-semibold text-sm ps-2 pe-3 py-1 bg-orange-100 text-orange-600 rounded"
                            }, React.createElement("span", {
                                  className: "fa-stack text-orange-400 me-2 shrink-0"
                                }, React.createElement("i", {
                                      className: "fas fa-circle fa-stack-2x"
                                    }), React.createElement("i", {
                                      className: "fas fa-hourglass-half fa-stack-1x fa-inverse"
                                    })), tr(undefined, undefined, "pending_review")), shouldRenderButton ? React.createElement("a", {
                                className: "flex items-center justify-center font-semibold text-sm ps-2 pe-3 py-1 bg-orange-100 btn-primary rounded mx-2 text-center",
                                href: window.pupilfirst.rootURL
                              }, tr(undefined, undefined, "quit_seb")) : React.createElement(React.Fragment, undefined), match$2 === 1 ? React.createElement(CoursesCurriculum__UndoButton.make, {
                                undoSubmissionCB: undoSubmissionCB,
                                targetId: CoursesCurriculum__Target.id(target)
                              }) : null);
                    break;
                case /* Completed */2 :
                    tmp = gradingSection(grades, evaluationCriteria, curriedGradeBar, true);
                    break;
                case /* Rejected */3 :
                    tmp = gradingSection(grades, evaluationCriteria, curriedGradeBar, false);
                    break;
                
              }
              return React.createElement("div", {
                          key: CoursesCurriculum__Submission.id(submission),
                          "aria-label": tr(undefined, undefined, "submission_details") + CoursesCurriculum__Submission.createdAtPretty(submission),
                          className: "mt-4 pb-4 relative curriculum__submission-feedback-container"
                        }, React.createElement("div", {
                              className: "flex justify-between items-end"
                            }, React.createElement("h2", {
                                  className: "ms-2 mb-2 font-semibold text-sm lg:text-base leading-tight"
                                }, completionType >= 4 ? tr(undefined, undefined, "form_response_number") + String(totalSubmissions - index | 0) : tr(undefined, undefined, "submission_number") + String(totalSubmissions - index | 0)), React.createElement("div", {
                                  className: "text-xs font-semibold bg-gray-50 inline-block px-3 py-1 me-2 rounded-t-lg border-t border-x text-gray-800 leading-tight"
                                }, completionType !== 1 || match !== 2 ? null : React.createElement("div", undefined, React.createElement(CoursesCurriculum__UndoButton.make, {
                                            undoSubmissionCB: undoSubmissionCB,
                                            targetId: CoursesCurriculum__Target.id(target)
                                          })), React.createElement("span", {
                                      className: "hidden md:inline"
                                    }, tr(undefined, undefined, "submitted_on")), CoursesCurriculum__Submission.createdAtPretty(submission))), React.createElement("div", {
                              className: "rounded-lg bg-gray-50 border shadow-md overflow-hidden"
                            }, React.createElement("div", {
                                  className: "px-4 py-4 md:px-6 md:pt-6 md:pb-5"
                                }, React.createElement(SubmissionChecklistShow.make, {
                                      checklist: CoursesCurriculum__Submission.checklist(submission),
                                      updateChecklistCB: undefined
                                    })), tmp, Js_array.map((function (feedback) {
                                    var coach = Belt_Option.flatMap(CoursesCurriculum__Feedback.coachId(feedback), (function (id) {
                                            return Js_array.find((function (c) {
                                                          return CoursesCurriculum__Coach.id(c) === id;
                                                        }), coaches);
                                          }));
                                    var user = coach !== undefined ? Js_array.find((function (up) {
                                              return CoursesCurriculum__User.id(up) === CoursesCurriculum__Coach.userId(coach);
                                            }), users) : undefined;
                                    var match = user !== undefined ? [
                                        CoursesCurriculum__User.name(user),
                                        CoursesCurriculum__User.title(user),
                                        CoursesCurriculum__User.avatar(user)
                                      ] : [
                                        tr(undefined, undefined, "unknown_coach"),
                                        undefined,
                                        React.createElement("div", {
                                              className: "w-10 h-10 rounded-full bg-gray-400 flex items-center justify-center"
                                            }, React.createElement("i", {
                                                  className: "fas fa-user-times"
                                                }))
                                      ];
                                    var coachTitle = match[1];
                                    return React.createElement("div", {
                                                key: CoursesCurriculum__Feedback.id(feedback),
                                                className: "bg-white border-t p-4 md:p-6"
                                              }, React.createElement("div", {
                                                    className: "flex items-center"
                                                  }, React.createElement("div", {
                                                        className: "shrink-0 w-12 h-12 bg-gray-300 rounded-full overflow-hidden ltr:mr me-3 object-cover"
                                                      }, match[2]), React.createElement("div", undefined, React.createElement("p", {
                                                            className: "text-xs leading-tight"
                                                          }, "Feedback from:"), React.createElement("div", undefined, React.createElement("h4", {
                                                                className: "font-semibold text-base leading-tight block md:inline-flex self-end"
                                                              }, match[0]), coachTitle !== undefined ? React.createElement("span", {
                                                                  className: "block md:inline-flex text-xs text-gray-800 ms-2 leading-tight self-end"
                                                                }, "(" + (coachTitle + ")")) : null))), React.createElement(MarkdownBlock.make, {
                                                    markdown: CoursesCurriculum__Feedback.feedback(feedback),
                                                    className: "ms-15",
                                                    profile: /* Permissive */0
                                                  }));
                                  }), Js_array.filter((function (feedback) {
                                        return CoursesCurriculum__Feedback.submissionId(feedback) === CoursesCurriculum__Submission.id(submission);
                                      }), CoursesCurriculum__TargetDetails.feedback(targetDetails)))));
            });
}

function addSubmission(setShowSubmissionForm, addSubmissionCB, submission) {
  Curry._1(setShowSubmissionForm, (function (param) {
          return false;
        }));
  return Curry._1(addSubmissionCB, submission);
}

function CoursesCurriculum__SubmissionsAndFeedback(Props) {
  var targetDetails = Props.targetDetails;
  var target = Props.target;
  var evaluationCriteria = Props.evaluationCriteria;
  var addSubmissionCB = Props.addSubmissionCB;
  var targetStatus = Props.targetStatus;
  var coaches = Props.coaches;
  var users = Props.users;
  var preview = Props.preview;
  var checklist = Props.checklist;
  var match = React.useState(function () {
        return false;
      });
  var setShowSubmissionForm = match[1];
  var showSubmissionForm = match[0];
  var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails);
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex justify-between items-end border-b pb-2"
                }, React.createElement("h4", {
                      className: "text-base md:text-xl"
                    }, completionType >= 4 ? tr(undefined, undefined, "your_responses") : tr(undefined, undefined, "your_submissions")), CoursesCurriculum__TargetStatus.canSubmit(CoursesCurriculum__Target.resubmittable(target), targetStatus) ? (
                    showSubmissionForm ? React.createElement("button", {
                            className: "btn btn-subtle",
                            onClick: (function (param) {
                                return handleAddAnotherSubmission(setShowSubmissionForm, param);
                              })
                          }, React.createElement(PfIcon.make, {
                                className: "if i-times-regular text-lg me-2"
                              }), React.createElement("span", {
                                className: "hidden md:inline"
                              }, tr(undefined, undefined, "cancel")), React.createElement("span", {
                                className: "md:hidden"
                              }, tr(undefined, undefined, "cancel"))) : React.createElement("button", {
                            className: "btn btn-primary",
                            onClick: (function (param) {
                                return handleAddAnotherSubmission(setShowSubmissionForm, param);
                              })
                          }, React.createElement(PfIcon.make, {
                                className: "if i-plus-regular text-lg me-2"
                              }), React.createElement("span", {
                                className: "hidden md:inline"
                              }, completionType >= 4 ? tr(undefined, undefined, "add_another_response") : tr(undefined, undefined, "add_another_submission")), React.createElement("span", {
                                className: "md:hidden"
                              }, tr(undefined, undefined, "add_another")))
                  ) : null), showSubmissionForm ? React.createElement(CoursesCurriculum__SubmissionBuilder.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: (function (param) {
                        Curry._1(setShowSubmissionForm, (function (param) {
                                return false;
                              }));
                        return Curry._1(addSubmissionCB, param);
                      }),
                    preview: preview,
                    checklist: checklist
                  }) : submissions(target, targetStatus, targetDetails, evaluationCriteria, coaches, users));
}

var make = CoursesCurriculum__SubmissionsAndFeedback;

export {
  str ,
  tr ,
  gradeBar ,
  statusBar ,
  submissionStatusIcon ,
  undoSubmissionCB ,
  gradingSection ,
  handleAddAnotherSubmission ,
  submissions ,
  addSubmission ,
  make ,
}
/*  Not a pure module */
