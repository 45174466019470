// Generated by ReScript, PLEASE EDIT WITH CARE


function toArray(t) {
  if (typeof t === "number") {
    return [];
  } else {
    return t._0;
  }
}

export {
  toArray ,
}
/* No side effect */
