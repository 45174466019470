// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as UrlUtils from "../../../shared/utils/UrlUtils.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as TargetChecklistItem from "../../../schools/courses/types/curriculum_editor/TargetChecklistItem.bs.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";

function title(t) {
  return t.title;
}

function result(t) {
  return t.result;
}

function optional(t) {
  return t.optional;
}

function make(result, title, optional) {
  return {
          title: title,
          optional: optional,
          result: result
        };
}

function fromTargetChecklistItem(targetChecklist) {
  return $$Array.map((function (tc) {
                var title = TargetChecklistItem.title(tc);
                var optional = TargetChecklistItem.optional(tc);
                var match = TargetChecklistItem.kind(tc);
                var result;
                if (typeof match === "number") {
                  switch (match) {
                    case /* Files */0 :
                        result = {
                          TAG: /* Files */0,
                          _0: []
                        };
                        break;
                    case /* Link */1 :
                        result = {
                          TAG: /* Link */1,
                          _0: ""
                        };
                        break;
                    case /* ShortText */2 :
                        result = {
                          TAG: /* ShortText */2,
                          _0: ""
                        };
                        break;
                    case /* LongText */3 :
                        result = {
                          TAG: /* LongText */3,
                          _0: ""
                        };
                        break;
                    case /* AudioRecord */4 :
                        result = {
                          TAG: /* AudioRecord */5,
                          _0: {
                            id: "",
                            name: ""
                          }
                        };
                        break;
                    
                  }
                } else {
                  result = {
                    TAG: /* MultiChoice */4,
                    _0: match._0,
                    _1: match._1,
                    _2: []
                  };
                }
                return {
                        title: title,
                        optional: optional,
                        result: result
                      };
              }), targetChecklist);
}

function updateResultAtIndex(index, result, checklist) {
  return Js_array.mapi((function (c, i) {
                if (i === index) {
                  return {
                          title: c.title,
                          optional: c.optional,
                          result: result
                        };
                } else {
                  return c;
                }
              }), checklist);
}

function makeFile(id, name) {
  return {
          id: id,
          name: name
        };
}

function filename(file) {
  return file.name;
}

function fileId(file) {
  return file.id;
}

function fileIds(checklist) {
  return ArrayUtils.flattenV2(Js_array.map((function (c) {
                    var files = c.result;
                    switch (files.TAG | 0) {
                      case /* Files */0 :
                          return Js_array.map((function (a) {
                                        return a.id;
                                      }), files._0);
                      case /* AudioRecord */5 :
                          return [files._0.id];
                      default:
                        return [];
                    }
                  }), checklist));
}

function kindAsString(t) {
  var match = t.result;
  switch (match.TAG | 0) {
    case /* Files */0 :
        return "files";
    case /* Link */1 :
        return "link";
    case /* ShortText */2 :
        return "shortText";
    case /* LongText */3 :
        return "longText";
    case /* MultiChoice */4 :
        return "multiChoice";
    case /* AudioRecord */5 :
        return "audio";
    
  }
}

function resultAsJson(t) {
  var files = t.result;
  switch (files.TAG | 0) {
    case /* Files */0 :
        return Js_array.map((function (file) {
                      return file.id;
                    }), files._0);
    case /* MultiChoice */4 :
        return files._2;
    case /* AudioRecord */5 :
        return files._0.id;
    default:
      return files._0;
  }
}

function validString(s, maxLength) {
  var length = s.trim().length;
  if (length >= 1) {
    return length <= maxLength;
  } else {
    return false;
  }
}

function validShortText(s) {
  return validString(s, 250);
}

function validLongText(s) {
  return validString(s, 5000);
}

function validFiles(files) {
  if (Caml_obj.notequal(files, [])) {
    return files.length < 4;
  } else {
    return false;
  }
}

function validMultiChoice(selected, choices) {
  if (ArrayUtils.isNotEmpty(selected)) {
    return selected.every(function (s) {
                return choices.includes(s);
              });
  } else {
    return false;
  }
}

function validResponse(response, allowBlank) {
  var optional = allowBlank ? response.optional : false;
  var match = response.result;
  switch (match.TAG | 0) {
    case /* Files */0 :
        var files = match._0;
        if (optional && ArrayUtils.isEmpty(files)) {
          return true;
        } else {
          return validFiles(files);
        }
    case /* Link */1 :
        var link = match._0;
        if (optional) {
          return UrlUtils.isValid(true, link);
        } else {
          return UrlUtils.isValid(false, link);
        }
    case /* ShortText */2 :
        var t = match._0;
        if (optional) {
          if (validString(t, 250)) {
            return true;
          } else {
            return t === "";
          }
        } else {
          return validString(t, 250);
        }
    case /* LongText */3 :
        var t$1 = match._0;
        if (optional) {
          if (validString(t$1, 5000)) {
            return true;
          } else {
            return t$1 === "";
          }
        } else {
          return validString(t$1, 5000);
        }
    case /* MultiChoice */4 :
        var selected = match._2;
        var choices = match._0;
        if (optional && ArrayUtils.isEmpty(selected)) {
          return true;
        } else {
          return validMultiChoice(selected, choices);
        }
    case /* AudioRecord */5 :
        if (optional) {
          return true;
        } else {
          return match._0.id !== "";
        }
    
  }
}

function validChecklist(checklist) {
  return ArrayUtils.isEmpty(Js_array.filter((function (c) {
                    return !c;
                  }), Js_array.map((function (c) {
                        return validResponse(c, true);
                      }), checklist)));
}

function validResponses(responses) {
  return Js_array.filter((function (c) {
                return validResponse(c, false);
              }), responses);
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "title",
                t.title
              ],
              tl: {
                hd: [
                  "kind",
                  kindAsString(t)
                ],
                tl: {
                  hd: [
                    "status",
                    "noAnswer"
                  ],
                  tl: {
                    hd: [
                      "result",
                      resultAsJson(t)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function encodeArray(checklist) {
  return Json_encode.array(encode, validResponses(checklist));
}

function makeFiles(checklist) {
  return $$Array.map((function (f) {
                var url = "/timeline_event_files/" + (f.id + "/download");
                return SubmissionChecklistItem.makeFile(f.name, url, f.id);
              }), ArrayUtils.flattenV2(Js_array.map((function (item) {
                        var files = item.result;
                        switch (files.TAG | 0) {
                          case /* Files */0 :
                              return files._0;
                          case /* AudioRecord */5 :
                              return [files._0];
                          default:
                            return [];
                        }
                      }), checklist)));
}

export {
  title ,
  result ,
  optional ,
  make ,
  fromTargetChecklistItem ,
  updateResultAtIndex ,
  makeFile ,
  filename ,
  fileId ,
  fileIds ,
  kindAsString ,
  resultAsJson ,
  validString ,
  validShortText ,
  validLongText ,
  validFiles ,
  validMultiChoice ,
  validResponse ,
  validChecklist ,
  validResponses ,
  encode ,
  encodeArray ,
  makeFiles ,
}
/* UrlUtils Not a pure module */
