// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "./utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import Zxcvbn from "zxcvbn";
import * as ArrayUtils from "./utils/ArrayUtils.bs.js";
import * as Caml_exceptions from "../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedZxcvbnScore = /* @__PURE__ */Caml_exceptions.create("Zxcvbn.UnexpectedZxcvbnScore");

var partial_arg = "components.Zxcvbn";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function label(t) {
  var match = t.strength;
  switch (match) {
    case /* Weak */0 :
        return Curry._3(ts, undefined, undefined, "weak");
    case /* Fair */1 :
        return Curry._3(ts, undefined, undefined, "fair");
    case /* Medium */2 :
        return Curry._3(ts, undefined, undefined, "medium");
    case /* Strong */3 :
        return Curry._3(ts, undefined, undefined, "strong");
    
  }
}

function colorClass(t, score) {
  if (t.score < score) {
    return "bg-gray-500";
  }
  var match = t.strength;
  switch (match) {
    case /* Weak */0 :
        return "bg-red-500";
    case /* Fair */1 :
        return "bg-orange-500";
    case /* Medium */2 :
        return "bg-yellow-500";
    case /* Strong */3 :
        return "bg-green-500";
    
  }
}

function suggestions(t) {
  return t.suggestions;
}

function score(t) {
  return t.score;
}

function make(userInputsOpt, password) {
  var userInputs = userInputsOpt !== undefined ? userInputsOpt : [];
  if (password.length <= 0) {
    return ;
  }
  var formDictionary = userInputs.map(function (val) {
        return val.split(" ").concat(val.split("@"));
      });
  var zxcvbnResponse = Zxcvbn(password, ArrayUtils.flattenV2(formDictionary));
  var score = zxcvbnResponse.score;
  var suggestions = zxcvbnResponse.feedback.suggestions;
  var strength;
  switch (score) {
    case 0 :
    case 1 :
        strength = /* Weak */0;
        break;
    case 2 :
        strength = /* Fair */1;
        break;
    case 3 :
        strength = /* Medium */2;
        break;
    case 4 :
        strength = /* Strong */3;
        break;
    default:
      throw {
            RE_EXN_ID: UnexpectedZxcvbnScore,
            _1: score,
            Error: new Error()
          };
  }
  return {
          score: score === 0 ? 1 : score,
          strength: strength,
          suggestions: suggestions
        };
}

export {
  make ,
  label ,
  colorClass ,
  suggestions ,
  score ,
}
/* I18n Not a pure module */
