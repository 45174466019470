// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

export {
  id ,
  title ,
  decode ,
}
/* No side effect */
