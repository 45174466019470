// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../../../shared/Icon.bs.js";
import * as React from "react";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";

function str(prim) {
  return prim;
}

function CoursesCurriculum__CompletionInstructions(Props) {
  var targetDetails = Props.targetDetails;
  var title = Props.title;
  var completionInstructions = CoursesCurriculum__TargetDetails.completionInstructions(targetDetails);
  if (completionInstructions !== undefined) {
    return React.createElement("div", {
                className: "flex flex-col sm:flex-row mt-4 bg-yellow-100 rounded-lg border border-yellow-500 overflow-hidden"
              }, React.createElement("div", {
                    className: "bg-yellow-500 p-4 sm:py-8 sm:px-7 shrink-0 text-center"
                  }, React.createElement(Icon.make, {
                        className: "if i-lamp-solid text-3xl sm:text-5xl text-yellow-800"
                      })), React.createElement("div", {
                    className: "p-5 text-center sm:text-left"
                  }, React.createElement("h4", {
                        className: "font-semibold text-lg"
                      }, title), React.createElement("p", {
                        className: "mt-1 leading-snug"
                      }, completionInstructions)));
  } else {
    return null;
  }
}

var make = CoursesCurriculum__CompletionInstructions;

export {
  str ,
  make ,
}
/* Icon Not a pure module */
