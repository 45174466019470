// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../shared/Api.bs.js";
import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Radio from "../shared/components/Radio.bs.js";
import * as React from "react";
import * as Avatar from "../shared/Avatar.bs.js";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as Locale from "../shared/utils/Locale.bs.js";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as Zxcvbn from "../shared/Zxcvbn.bs.js";
import * as Caml_obj from "../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../node_modules/rescript/lib/es6/caml_array.js";
import * as EmailUtils from "../shared/utils/EmailUtils.bs.js";
import * as Js_promise from "../../../node_modules/rescript/lib/es6/js_promise.js";
import * as ReactUtils from "../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as OptionUtils from "../shared/utils/OptionUtils.bs.js";
import * as StringUtils from "../shared/utils/StringUtils.bs.js";
import * as ThemeSwitch from "../shared/components/ThemeSwitch.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../shared/Notification.bs.js";
import * as ConfirmWindow from "../shared/components/ConfirmWindow.bs.js";
import * as AuthenticityToken from "../shared/utils/AuthenticityToken.bs.js";
import * as School__InputGroupError from "../schools/shared/components/School__InputGroupError.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.UserEdit";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* StartSaving */0 :
          var newrecord = Caml_obj.obj_dup(state);
          newrecord.saving = true;
          return newrecord;
      case /* ResetSaving */1 :
          var newrecord$1 = Caml_obj.obj_dup(state);
          newrecord$1.saving = false;
          return newrecord$1;
      case /* StartDeletingAccount */2 :
          var newrecord$2 = Caml_obj.obj_dup(state);
          newrecord$2.deletingAccount = true;
          return newrecord$2;
      case /* FinishAccountDeletion */3 :
          var newrecord$3 = Caml_obj.obj_dup(state);
          newrecord$3.deletingAccount = false;
          newrecord$3.showDeleteAccountForm = false;
          newrecord$3.emailForAccountDeletion = "";
          return newrecord$3;
      case /* ShowEmailChangePasswordConfirm */4 :
          var newrecord$4 = Caml_obj.obj_dup(state);
          newrecord$4.showEmailChangePasswordConfirm = true;
          return newrecord$4;
      case /* InitiatePasswordReset */5 :
          var newrecord$5 = Caml_obj.obj_dup(state);
          newrecord$5.initiatePasswordReset = true;
          return newrecord$5;
      case /* FinishPasswordReset */6 :
          var newrecord$6 = Caml_obj.obj_dup(state);
          newrecord$6.initiatePasswordReset = false;
          return newrecord$6;
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateName */0 :
          var newrecord$7 = Caml_obj.obj_dup(state);
          newrecord$7.dirty = true;
          newrecord$7.name = action._0;
          return newrecord$7;
      case /* UpdatePreferredName */1 :
          var newrecord$8 = Caml_obj.obj_dup(state);
          newrecord$8.dirty = true;
          newrecord$8.preferredName = action._0;
          return newrecord$8;
      case /* UpdateAbout */2 :
          var newrecord$9 = Caml_obj.obj_dup(state);
          newrecord$9.dirty = true;
          newrecord$9.about = action._0;
          return newrecord$9;
      case /* UpdateEmail */3 :
          var newrecord$10 = Caml_obj.obj_dup(state);
          newrecord$10.dirty = true;
          newrecord$10.email = action._0;
          return newrecord$10;
      case /* SetDisableUpdateEmail */4 :
          var newrecord$11 = Caml_obj.obj_dup(state);
          newrecord$11.disableEmailInput = action._0;
          return newrecord$11;
      case /* UpdatePhone */5 :
          var newrecord$12 = Caml_obj.obj_dup(state);
          newrecord$12.dirty = true;
          newrecord$12.phone = action._0;
          return newrecord$12;
      case /* UpdateIdentity */6 :
          var newrecord$13 = Caml_obj.obj_dup(state);
          newrecord$13.dirty = true;
          newrecord$13.identity = action._0;
          return newrecord$13;
      case /* UpdateLocale */7 :
          var newrecord$14 = Caml_obj.obj_dup(state);
          newrecord$14.dirty = true;
          newrecord$14.locale = action._0;
          return newrecord$14;
      case /* UpdateCurrentPassword */8 :
          var newrecord$15 = Caml_obj.obj_dup(state);
          newrecord$15.dirty = true;
          newrecord$15.currentPassword = action._0;
          return newrecord$15;
      case /* UpdateNewPassword */9 :
          var newrecord$16 = Caml_obj.obj_dup(state);
          newrecord$16.dirty = true;
          newrecord$16.newPassword = action._0;
          return newrecord$16;
      case /* UpdateNewPassWordConfirm */10 :
          var newrecord$17 = Caml_obj.obj_dup(state);
          newrecord$17.dirty = true;
          newrecord$17.confirmPassword = action._0;
          return newrecord$17;
      case /* UpdateEmailForDeletion */11 :
          var newrecord$18 = Caml_obj.obj_dup(state);
          newrecord$18.emailForAccountDeletion = action._0;
          return newrecord$18;
      case /* UpdateDailyDigest */12 :
          var newrecord$19 = Caml_obj.obj_dup(state);
          newrecord$19.dirty = true;
          newrecord$19.dailyDigest = action._0;
          return newrecord$19;
      case /* UpdateThemePreference */13 :
          var themePreference = action._0;
          localStorage.setItem("themePreference", themePreference);
          ThemeSwitch.setThemeBasedOnPreference(undefined);
          var newrecord$20 = Caml_obj.obj_dup(state);
          newrecord$20.themePreference = themePreference;
          return newrecord$20;
      case /* UpdateAvatarUrl */14 :
          var newrecord$21 = Caml_obj.obj_dup(state);
          newrecord$21.avatarUploadError = undefined;
          newrecord$21.avatarUrl = action._0;
          return newrecord$21;
      case /* ChangeDeleteAccountFormVisibility */15 :
          var newrecord$22 = Caml_obj.obj_dup(state);
          newrecord$22.showDeleteAccountForm = action._0;
          newrecord$22.emailForAccountDeletion = "";
          return newrecord$22;
      case /* SetAvatarUploadError */16 :
          var newrecord$23 = Caml_obj.obj_dup(state);
          newrecord$23.avatarUploadError = action._0;
          return newrecord$23;
      case /* FinishSaving */17 :
          var newrecord$24 = Caml_obj.obj_dup(state);
          newrecord$24.dirty = false;
          newrecord$24.saving = false;
          newrecord$24.hasCurrentPassword = action._0;
          newrecord$24.confirmPassword = "";
          newrecord$24.newPassword = "";
          newrecord$24.currentPassword = "";
          return newrecord$24;
      case /* UpdateEmailAndDisableInput */18 :
          var newrecord$25 = Caml_obj.obj_dup(state);
          newrecord$25.dirty = true;
          newrecord$25.showEmailChangePasswordConfirm = false;
          newrecord$25.passwordForEmailChange = "";
          newrecord$25.disableEmailInput = true;
          newrecord$25.email = action._0;
          return newrecord$25;
      case /* UpdatePasswordForEmailChange */19 :
          var newrecord$26 = Caml_obj.obj_dup(state);
          newrecord$26.passwordForEmailChange = action._0;
          return newrecord$26;
      
    }
  }
}

var Raw = {};

var query = "mutation UpdateUserMutation($name: String!, $preferredName: String, $about: String, $phone: String, $identity: String, $locale: String!, $currentPassword: String, $newPassword: String, $confirmPassword: String, $dailyDigest: Boolean!)  {\nupdateUser(name: $name, preferredName: $preferredName, about: $about, phone: $phone, identity: $identity, locale: $locale, currentPassword: $currentPassword, newPassword: $newPassword, confirmNewPassword: $confirmPassword, dailyDigest: $dailyDigest)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.updateUser;
  return {
          updateUser: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.updateUser;
  var value$2 = value$1.success;
  var updateUser = {
    success: value$2
  };
  return {
          updateUser: updateUser
        };
}

function serializeVariables(inp) {
  var a = inp.preferredName;
  var a$1 = inp.about;
  var a$2 = inp.phone;
  var a$3 = inp.identity;
  var a$4 = inp.currentPassword;
  var a$5 = inp.newPassword;
  var a$6 = inp.confirmPassword;
  return {
          name: inp.name,
          preferredName: a !== undefined ? a : undefined,
          about: a$1 !== undefined ? a$1 : undefined,
          phone: a$2 !== undefined ? a$2 : undefined,
          identity: a$3 !== undefined ? a$3 : undefined,
          locale: inp.locale,
          currentPassword: a$4 !== undefined ? a$4 : undefined,
          newPassword: a$5 !== undefined ? a$5 : undefined,
          confirmPassword: a$6 !== undefined ? a$6 : undefined,
          dailyDigest: inp.dailyDigest
        };
}

function makeVariables(name, preferredName, about, phone, identity, locale, currentPassword, newPassword, confirmPassword, dailyDigest, param) {
  return {
          name: name,
          preferredName: preferredName,
          about: about,
          phone: phone,
          identity: identity,
          locale: locale,
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          dailyDigest: dailyDigest
        };
}

var UpdateUserQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var UpdateUserQuery_Graphql_error = include.Graphql_error;

var UpdateUserQuery_decodeNotification = include.decodeNotification;

var UpdateUserQuery_decodeObject = include.decodeObject;

var UpdateUserQuery_decodeNotifications = include.decodeNotifications;

var UpdateUserQuery_decodeErrors = include.decodeErrors;

var UpdateUserQuery_flashNotifications = include.flashNotifications;

var UpdateUserQuery_sendQuery = include.sendQuery;

var UpdateUserQuery_query = include.query;

var UpdateUserQuery_make = include.make;

var UpdateUserQuery = {
  UpdateUserQuery_inner: UpdateUserQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: UpdateUserQuery_Graphql_error,
  decodeNotification: UpdateUserQuery_decodeNotification,
  decodeObject: UpdateUserQuery_decodeObject,
  decodeNotifications: UpdateUserQuery_decodeNotifications,
  decodeErrors: UpdateUserQuery_decodeErrors,
  flashNotifications: UpdateUserQuery_flashNotifications,
  sendQuery: UpdateUserQuery_sendQuery,
  query: UpdateUserQuery_query,
  $$fetch: $$fetch,
  make: UpdateUserQuery_make
};

var Raw$1 = {};

var query$1 = "mutation InitiateAccountDeletionMutation($email: String!)  {\ninitiateAccountDeletion(email: $email)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.initiateAccountDeletion;
  return {
          initiateAccountDeletion: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.initiateAccountDeletion;
  var value$2 = value$1.success;
  var initiateAccountDeletion = {
    success: value$2
  };
  return {
          initiateAccountDeletion: initiateAccountDeletion
        };
}

function serializeVariables$1(inp) {
  return {
          email: inp.email
        };
}

function makeVariables$1(email, param) {
  return {
          email: email
        };
}

var InitiateAccountDeletionQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var InitiateAccountDeletionQuery_Graphql_error = include$1.Graphql_error;

var InitiateAccountDeletionQuery_decodeNotification = include$1.decodeNotification;

var InitiateAccountDeletionQuery_decodeObject = include$1.decodeObject;

var InitiateAccountDeletionQuery_decodeNotifications = include$1.decodeNotifications;

var InitiateAccountDeletionQuery_decodeErrors = include$1.decodeErrors;

var InitiateAccountDeletionQuery_flashNotifications = include$1.flashNotifications;

var InitiateAccountDeletionQuery_sendQuery = include$1.sendQuery;

var InitiateAccountDeletionQuery_query = include$1.query;

var InitiateAccountDeletionQuery_make = include$1.make;

var InitiateAccountDeletionQuery = {
  InitiateAccountDeletionQuery_inner: InitiateAccountDeletionQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: InitiateAccountDeletionQuery_Graphql_error,
  decodeNotification: InitiateAccountDeletionQuery_decodeNotification,
  decodeObject: InitiateAccountDeletionQuery_decodeObject,
  decodeNotifications: InitiateAccountDeletionQuery_decodeNotifications,
  decodeErrors: InitiateAccountDeletionQuery_decodeErrors,
  flashNotifications: InitiateAccountDeletionQuery_flashNotifications,
  sendQuery: InitiateAccountDeletionQuery_sendQuery,
  query: InitiateAccountDeletionQuery_query,
  $$fetch: $$fetch$1,
  make: InitiateAccountDeletionQuery_make
};

var Raw$2 = {};

var query$2 = "mutation SendUpdateEmailToken($newEmail: String!, $password: String!)  {\nsendUpdateEmailToken(newEmail: $newEmail, password: $password)  {\nsuccess  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = value.sendUpdateEmailToken;
  return {
          sendUpdateEmailToken: {
            success: value$1.success
          }
        };
}

function serialize$2(value) {
  var value$1 = value.sendUpdateEmailToken;
  var value$2 = value$1.success;
  var sendUpdateEmailToken = {
    success: value$2
  };
  return {
          sendUpdateEmailToken: sendUpdateEmailToken
        };
}

function serializeVariables$2(inp) {
  return {
          newEmail: inp.newEmail,
          password: inp.password
        };
}

function makeVariables$2(newEmail, password, param) {
  return {
          newEmail: newEmail,
          password: password
        };
}

var SendEmailUpdateTokenQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$2 = include$2.$$fetch;

var SendEmailUpdateTokenQuery_Graphql_error = include$2.Graphql_error;

var SendEmailUpdateTokenQuery_decodeNotification = include$2.decodeNotification;

var SendEmailUpdateTokenQuery_decodeObject = include$2.decodeObject;

var SendEmailUpdateTokenQuery_decodeNotifications = include$2.decodeNotifications;

var SendEmailUpdateTokenQuery_decodeErrors = include$2.decodeErrors;

var SendEmailUpdateTokenQuery_flashNotifications = include$2.flashNotifications;

var SendEmailUpdateTokenQuery_sendQuery = include$2.sendQuery;

var SendEmailUpdateTokenQuery_query = include$2.query;

var SendEmailUpdateTokenQuery_make = include$2.make;

var SendEmailUpdateTokenQuery = {
  SendEmailUpdateTokenQuery_inner: SendEmailUpdateTokenQuery_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: SendEmailUpdateTokenQuery_Graphql_error,
  decodeNotification: SendEmailUpdateTokenQuery_decodeNotification,
  decodeObject: SendEmailUpdateTokenQuery_decodeObject,
  decodeNotifications: SendEmailUpdateTokenQuery_decodeNotifications,
  decodeErrors: SendEmailUpdateTokenQuery_decodeErrors,
  flashNotifications: SendEmailUpdateTokenQuery_flashNotifications,
  sendQuery: SendEmailUpdateTokenQuery_sendQuery,
  query: SendEmailUpdateTokenQuery_query,
  $$fetch: $$fetch$2,
  make: SendEmailUpdateTokenQuery_make
};

var Raw$3 = {};

var query$3 = "mutation InitiatePasswordResetMutation($email: String!)  {\ninitiatePasswordReset(email: $email)  {\nsuccess  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = value.initiatePasswordReset;
  return {
          initiatePasswordReset: {
            success: value$1.success
          }
        };
}

function serialize$3(value) {
  var value$1 = value.initiatePasswordReset;
  var value$2 = value$1.success;
  var initiatePasswordReset = {
    success: value$2
  };
  return {
          initiatePasswordReset: initiatePasswordReset
        };
}

function serializeVariables$3(inp) {
  return {
          email: inp.email
        };
}

function makeVariables$3(email, param) {
  return {
          email: email
        };
}

var InitiatePasswordResetQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = GraphqlQuery.Extender({
      Raw: Raw$3,
      query: query$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$3 = include$3.$$fetch;

var InitiatePasswordResetQuery_Graphql_error = include$3.Graphql_error;

var InitiatePasswordResetQuery_decodeNotification = include$3.decodeNotification;

var InitiatePasswordResetQuery_decodeObject = include$3.decodeObject;

var InitiatePasswordResetQuery_decodeNotifications = include$3.decodeNotifications;

var InitiatePasswordResetQuery_decodeErrors = include$3.decodeErrors;

var InitiatePasswordResetQuery_flashNotifications = include$3.flashNotifications;

var InitiatePasswordResetQuery_sendQuery = include$3.sendQuery;

var InitiatePasswordResetQuery_query = include$3.query;

var InitiatePasswordResetQuery_make = include$3.make;

var InitiatePasswordResetQuery = {
  InitiatePasswordResetQuery_inner: InitiatePasswordResetQuery_inner,
  Raw: Raw$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  Graphql_error: InitiatePasswordResetQuery_Graphql_error,
  decodeNotification: InitiatePasswordResetQuery_decodeNotification,
  decodeObject: InitiatePasswordResetQuery_decodeObject,
  decodeNotifications: InitiatePasswordResetQuery_decodeNotifications,
  decodeErrors: InitiatePasswordResetQuery_decodeErrors,
  flashNotifications: InitiatePasswordResetQuery_flashNotifications,
  sendQuery: InitiatePasswordResetQuery_sendQuery,
  query: InitiatePasswordResetQuery_query,
  $$fetch: $$fetch$3,
  make: InitiatePasswordResetQuery_make
};

function uploadAvatar(send, formData) {
  Api.sendFormData("/user/upload_avatar", formData, (function (json) {
          $$Notification.success(ts(undefined, undefined, "notifications.done_exclamation"), t(undefined, undefined, "avatar_uploaded_notification"));
          var avatarUrl = Json_decode.field("avatarUrl", Json_decode.string, json);
          Curry._1(send, {
                TAG: /* UpdateAvatarUrl */14,
                _0: avatarUrl
              });
        }), (function (param) {
          Curry._1(send, {
                TAG: /* SetAvatarUploadError */16,
                _0: t(undefined, undefined, "upload_failed")
              });
        }));
}

function updateEmail(send, email, newEmail, password) {
  Curry._1(send, {
        TAG: /* SetDisableUpdateEmail */4,
        _0: false
      });
  Js_promise.$$catch((function (param) {
          Curry._1(send, {
                TAG: /* UpdateEmailAndDisableInput */18,
                _0: email
              });
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (param) {
              Curry._1(send, {
                    TAG: /* UpdateEmailAndDisableInput */18,
                    _0: newEmail
                  });
              return Promise.resolve(undefined);
            }), Curry._3($$fetch$2, undefined, undefined, {
                newEmail: newEmail,
                password: password
              })));
}

function submitAvatarForm(send, formId) {
  var element = document.querySelector("#" + formId);
  if (element == null) {
    Rollbar.error("Could not find form to upload file for content block: " + formId);
    return ;
  } else {
    return uploadAvatar(send, new FormData(element));
  }
}

function handleAvatarInputChange(send, formId, $$event) {
  $$event.preventDefault();
  var files = $$event.target.files;
  if (files.length === 0) {
    return ;
  }
  var file = Caml_array.get(files, 0);
  var isInvalidImageFile = true;
  if (file.size <= window.pupilfirst.maxUploadFileSize) {
    var match = file.type;
    var tmp;
    switch (match) {
      case "image/gif" :
      case "image/jpeg" :
      case "image/png" :
          tmp = false;
          break;
      default:
        tmp = true;
    }
    isInvalidImageFile = tmp;
  }
  var error = isInvalidImageFile ? t(undefined, undefined, "select_image_limit") : undefined;
  if (error !== undefined) {
    return Curry._1(send, {
                TAG: /* SetAvatarUploadError */16,
                _0: error
              });
  } else {
    return submitAvatarForm(send, formId);
  }
}

function initiatePasswordReset(send, email) {
  Curry._1(send, /* InitiatePasswordReset */5);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* FinishPasswordReset */6);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (param) {
              Curry._1(send, /* FinishPasswordReset */6);
              return Promise.resolve(undefined);
            }), Curry._3($$fetch$3, undefined, undefined, {
                email: email
              })));
}

function updateUser(state, send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* StartSaving */0);
  var variables = makeVariables(state.name, state.preferredName, state.about, state.phone, state.identity, state.locale, state.currentPassword, state.newPassword, state.confirmPassword, state.dailyDigest, undefined);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* ResetSaving */1);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (result) {
              if (result.updateUser.success) {
                var hasCurrentPassword = state.newPassword.length !== 0;
                Curry._1(send, {
                      TAG: /* FinishSaving */17,
                      _0: hasCurrentPassword
                    });
              } else {
                Curry._1(send, {
                      TAG: /* FinishSaving */17,
                      _0: state.hasCurrentPassword
                    });
              }
              return Promise.resolve(undefined);
            }), Curry._3($$fetch, undefined, undefined, variables)));
}

function initiateAccountDeletion(state, send) {
  Curry._1(send, /* StartDeletingAccount */2);
  Js_promise.$$catch((function (param) {
          Curry._1(send, /* FinishAccountDeletion */3);
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (result) {
              Curry._1(send, /* FinishAccountDeletion */3);
              return Promise.resolve(undefined);
            }), Curry._3($$fetch$1, undefined, undefined, {
                email: state.emailForAccountDeletion
              })));
}

function hasInvalidPassword(state) {
  return !(state.newPassword === "" && state.confirmPassword === "" || state.newPassword === state.confirmPassword && state.newPassword.length >= 8);
}

function saveDisabled(state) {
  if (hasInvalidPassword(state) || $$String.trim(state.name).length < 2) {
    return true;
  } else {
    return !state.dirty;
  }
}

function confirmEmailChangeWindow(currentEmail, state, send) {
  if (!state.showEmailChangePasswordConfirm) {
    return null;
  }
  var body = React.createElement("div", {
        "aria-label": t(undefined, undefined, "confirm_dialog_aria")
      }, React.createElement("p", {
            className: "text-sm text-center ltr:sm:text-left rtl:sm:text-right text-gray-600"
          }, t(undefined, undefined, "email_change_q")), React.createElement("div", {
            className: "mt-3"
          }, React.createElement("label", {
                className: "block text-sm font-semibold",
                htmlFor: "password"
              }, t(undefined, undefined, "confirm_using_password")), React.createElement("input", {
                className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                id: "password",
                autoComplete: "off",
                placeholder: t(undefined, undefined, "current_password_placeholder"),
                type: "password",
                value: state.passwordForEmailChange,
                onChange: (function ($$event) {
                    Curry._1(send, {
                          TAG: /* UpdatePasswordForEmailChange */19,
                          _0: $$event.target.value
                        });
                  })
              })));
  return React.createElement(ConfirmWindow.make, {
              title: t(undefined, undefined, "change_account_email"),
              body: body,
              confirmButtonText: t(undefined, undefined, "update_email"),
              cancelButtonText: t(undefined, undefined, "cancel"),
              onConfirm: (function (param) {
                  updateEmail(send, currentEmail, state.email, state.passwordForEmailChange);
                }),
              onCancel: (function (param) {
                  Curry._1(send, {
                        TAG: /* UpdateEmailAndDisableInput */18,
                        _0: currentEmail
                      });
                }),
              disableConfirm: state.passwordForEmailChange.length < 1,
              alertType: "Normal"
            });
}

function confirmDeletionWindow(state, send) {
  if (!state.showDeleteAccountForm) {
    return null;
  }
  var body = React.createElement("div", {
        "aria-label": t(undefined, undefined, "confirm_dialog_aria")
      }, React.createElement("p", {
            className: "text-sm text-center ltr:sm:text-left rtl:sm:text-right text-gray-600"
          }, t(undefined, undefined, "account_delete_q")), React.createElement("div", {
            className: "mt-3"
          }, React.createElement("label", {
                className: "block text-sm font-semibold",
                htmlFor: "email"
              }, t(undefined, undefined, "confirm_email")), React.createElement("input", {
                className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                id: "email",
                autoComplete: "off",
                placeholder: t(undefined, undefined, "email_placeholder"),
                type: "email",
                value: state.emailForAccountDeletion,
                onChange: (function ($$event) {
                    Curry._1(send, {
                          TAG: /* UpdateEmailForDeletion */11,
                          _0: $$event.target.value
                        });
                  })
              })));
  return React.createElement(ConfirmWindow.make, {
              title: t(undefined, undefined, "delete_account"),
              body: body,
              confirmButtonText: t(undefined, undefined, "initiate_deletion"),
              cancelButtonText: t(undefined, undefined, "cancel"),
              onConfirm: (function (param) {
                  initiateAccountDeletion(state, send);
                }),
              onCancel: (function (param) {
                  Curry._1(send, {
                        TAG: /* ChangeDeleteAccountFormVisibility */15,
                        _0: false
                      });
                }),
              disableConfirm: state.deletingAccount || state.email !== state.emailForAccountDeletion,
              alertType: "Critical"
            });
}

function themeChip(theme) {
  return React.createElement("div", {
              className: theme + " w-16 h-8 grid grid-cols-3 gap-1 p-1 rounded-md border border-gray-200 bg-white"
            }, React.createElement("div", {
                  className: "w-full h-full col-span-1 grid grid-rows-2 gap-1"
                }, React.createElement("div", {
                      className: "bg-black h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-primary-500 h-full w-full rounded-md"
                    })), React.createElement("div", {
                  className: "col-span-2 grid grid-cols-3 gap-1"
                }, React.createElement("div", {
                      className: "bg-primary-300 h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-primary-200 h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-primary-100 h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-gray-400 h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-gray-300 h-full w-full rounded-md"
                    }), React.createElement("div", {
                      className: "bg-gray-200 h-full w-full rounded-md"
                    })));
}

function UserEdit(Props) {
  var name = Props.name;
  var preferredName = Props.preferredName;
  var hasCurrentPassword = Props.hasCurrentPassword;
  var about = Props.about;
  var phone = Props.phone;
  var identity = Props.identity;
  var locale = Props.locale;
  var availableLocales = Props.availableLocales;
  var avatarUrl = Props.avatarUrl;
  var dailyDigest = Props.dailyDigest;
  var isUndeletableUser = Props.isUndeletableUser;
  var hasValidDeleteAccountToken = Props.hasValidDeleteAccountToken;
  var email = Props.email;
  var schoolName = Props.schoolName;
  var initialState_dailyDigest = OptionUtils.mapWithDefault((function (d) {
          return d;
        }), false, dailyDigest);
  var initialState_themePreference = Belt_Option.getWithDefault(Caml_option.null_to_opt(localStorage.getItem("themePreference")), "light");
  var initialState = {
    name: name,
    preferredName: preferredName,
    about: about,
    phone: phone,
    identity: identity,
    locale: locale,
    email: email,
    disableEmailInput: true,
    avatarUrl: avatarUrl,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    passwordForEmailChange: "",
    showEmailChangePasswordConfirm: false,
    dailyDigest: initialState_dailyDigest,
    themePreference: initialState_themePreference,
    emailForAccountDeletion: "",
    showDeleteAccountForm: false,
    hasCurrentPassword: hasCurrentPassword,
    deletingAccount: false,
    avatarUploadError: undefined,
    initiatePasswordReset: false,
    saving: false,
    dirty: false
  };
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  var url = state.avatarUrl;
  var error = state.avatarUploadError;
  var tmp;
  if (state.hasCurrentPassword) {
    var zxcvbn = Zxcvbn.make([
          state.name,
          state.email,
          schoolName
        ], state.newPassword);
    var tmp$1;
    if (zxcvbn !== undefined) {
      var zxcvbn$1 = Caml_option.valFromOption(zxcvbn);
      var suggestion = ArrayUtils.getOpt(Zxcvbn.suggestions(zxcvbn$1), 0);
      tmp$1 = React.createElement("div", {
            className: "h-6"
          }, React.createElement("div", {
                className: "flex justify-between items-center"
              }, React.createElement("p", {
                    className: "text-xs text-gray-400 font-inter"
                  }, ts(undefined, undefined, "password_strength")), React.createElement("div", {
                    className: "flex items-center gap-1 mt-1"
                  }, React.createElement("span", {
                        key: "0",
                        className: "text-xs text-gray-400 pe-2 text-right rtl:text-left"
                      }, Zxcvbn.label(zxcvbn$1)), [
                      1,
                      2,
                      3,
                      4
                    ].map(function (score) {
                        return React.createElement("span", {
                                    key: String(score),
                                    className: "rounded-md h-1 " + Zxcvbn.colorClass(zxcvbn$1, score) + " w-10"
                                  });
                      }))), React.createElement("div", undefined, React.createElement("ul", {
                    className: "text-yellow-900 text-[10px]"
                  }, suggestion !== undefined ? React.createElement("li", undefined, React.createElement(PfIcon.make, {
                              className: "if i-info-light if-fw"
                            }), suggestion) : null)));
    } else {
      tmp$1 = React.createElement("div", {
            className: "h-6"
          });
    }
    tmp = React.createElement("div", {
          className: "mb-4"
        }, React.createElement("p", {
              className: "font-semibold"
            }, t(undefined, undefined, "change_password")), React.createElement("div", {
              className: "mt-6"
            }, React.createElement("label", {
                  className: "block text-sm font-semibold",
                  htmlFor: "current_password"
                }, t(undefined, undefined, "current_password")), React.createElement("input", {
                  className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                  id: "current_password",
                  autoComplete: "off",
                  placeholder: t(undefined, undefined, "password_placeholder"),
                  type: "password",
                  value: state.currentPassword,
                  onChange: (function ($$event) {
                      Curry._1(send, {
                            TAG: /* UpdateCurrentPassword */8,
                            _0: $$event.target.value
                          });
                    })
                })), React.createElement("div", {
              className: "mt-6"
            }, React.createElement("label", {
                  className: "block text-sm font-semibold",
                  htmlFor: "new_password"
                }, t(undefined, undefined, "new_password")), React.createElement("input", {
                  className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                  id: "new_password",
                  autoComplete: "off",
                  placeholder: t(undefined, undefined, "new_password_placeholder"),
                  type: "password",
                  value: state.newPassword,
                  onChange: (function ($$event) {
                      Curry._1(send, {
                            TAG: /* UpdateNewPassword */9,
                            _0: $$event.target.value
                          });
                    })
                }), tmp$1), React.createElement("div", {
              className: "mt-6"
            }, React.createElement("label", {
                  className: "block text-sm font-semibold",
                  autoComplete: "off",
                  htmlFor: "confirm_password"
                }, t(undefined, undefined, "confirm_password")), React.createElement("input", {
                  className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                  id: "confirm_password",
                  autoComplete: "off",
                  placeholder: t(undefined, undefined, "confirm_password_placeholder"),
                  type: "password",
                  value: state.confirmPassword,
                  onChange: (function ($$event) {
                      Curry._1(send, {
                            TAG: /* UpdateNewPassWordConfirm */10,
                            _0: $$event.target.value
                          });
                    })
                }), ReactUtils.nullUnless(React.createElement(School__InputGroupError.make, {
                      message: t(undefined, undefined, "confirm_password_error"),
                      active: hasInvalidPassword(state)
                    }), StringUtils.isPresent(state.confirmPassword))));
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "container mx-auto px-3 pt-4 pb-8 max-w-5xl"
            }, confirmEmailChangeWindow(email, state, send), confirmDeletionWindow(state, send), React.createElement("div", {
                  className: "bg-white max-w-5xl mx-auto shadow sm:rounded-lg mt-4"
                }, React.createElement("div", {
                      className: "px-4 py-5 sm:p-6"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "edit_profile")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "displayed_publicly"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("div", {
                                  className: ""
                                }, React.createElement("div", {
                                      className: ""
                                    }, React.createElement("label", {
                                          className: "block text-sm font-semibold",
                                          htmlFor: "user_name"
                                        }, ts(undefined, undefined, "name")))), React.createElement("input", {
                                  className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                  id: "user_name",
                                  autoFocus: true,
                                  name: "name",
                                  placeholder: t(undefined, undefined, "name_placeholder"),
                                  value: state.name,
                                  onChange: (function ($$event) {
                                      Curry._1(send, {
                                            TAG: /* UpdateName */0,
                                            _0: $$event.target.value
                                          });
                                    })
                                }), React.createElement(School__InputGroupError.make, {
                                  message: t(undefined, undefined, "name_error"),
                                  active: $$String.trim(state.name).length < 1
                                }), React.createElement(School__InputGroupError.make, {
                                  message: t(undefined, undefined, "name_error_length"),
                                  active: $$String.trim(state.name).length === 1
                                }), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "user_preferred_name"
                                    }, ts(undefined, undefined, "preferred_name")), React.createElement("input", {
                                      className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "user_preferred_name",
                                      name: "preferred_name",
                                      placeholder: t(undefined, undefined, "preferred_name_placeholder"),
                                      value: state.preferredName,
                                      onChange: (function ($$event) {
                                          Curry._1(send, {
                                                TAG: /* UpdatePreferredName */1,
                                                _0: $$event.target.value
                                              });
                                        })
                                    })), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "about"
                                    }, t(undefined, undefined, "about")), React.createElement("div", undefined, React.createElement("textarea", {
                                          className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                          id: "about",
                                          placeholder: t(undefined, undefined, "about_placeholder"),
                                          rows: 3,
                                          value: state.about,
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateAbout */2,
                                                    _0: $$event.target.value
                                                  });
                                            })
                                        }))), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "phone"
                                    }, t(undefined, undefined, "phone")), React.createElement("input", {
                                      className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      dir: "ltr",
                                      id: "phone",
                                      name: "phone",
                                      placeholder: t(undefined, undefined, "phone_placeholder"),
                                      value: state.phone,
                                      onChange: (function ($$event) {
                                          Curry._1(send, {
                                                TAG: /* UpdatePhone */5,
                                                _0: $$event.target.value
                                              });
                                        })
                                    })), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "identity"
                                    }, t(undefined, undefined, "identity")), React.createElement("input", {
                                      className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "identity",
                                      name: "identity",
                                      placeholder: t(undefined, undefined, "identity_placeholder"),
                                      type: "number",
                                      value: state.identity,
                                      onChange: (function ($$event) {
                                          Curry._1(send, {
                                                TAG: /* UpdateIdentity */6,
                                                _0: $$event.target.value
                                              });
                                        })
                                    })), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("form", {
                                      id: "user-avatar-uploader"
                                    }, React.createElement("input", {
                                          name: "authenticity_token",
                                          type: "hidden",
                                          value: AuthenticityToken.fromHead(undefined)
                                        }), React.createElement("label", {
                                          className: "block text-sm font-semibold"
                                        }, t(undefined, undefined, "photo")), React.createElement("div", {
                                          className: "mt-2 flex items-center"
                                        }, React.createElement("span", {
                                              className: "inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-50 border-2 boder-gray-400"
                                            }, url !== undefined ? React.createElement("img", {
                                                    src: url
                                                  }) : React.createElement(Avatar.make, {
                                                    name: name
                                                  })), React.createElement("span", {
                                              className: "ms-5 inline-flex"
                                            }, React.createElement("input", {
                                                  "aria-label": "User Avatar",
                                                  className: "form-input__file-sr-only",
                                                  id: "user-edit__avatar-input",
                                                  multiple: false,
                                                  name: "user[avatar]",
                                                  required: false,
                                                  type: "file",
                                                  onChange: (function (param) {
                                                      return handleAvatarInputChange(send, "user-avatar-uploader", param);
                                                    })
                                                }), React.createElement("label", {
                                                  "aria-hidden": true,
                                                  className: "form-input__file-label shadow-sm py-2 px-3 border border-gray-300 rounded-md text-sm font-semibold hover:text-gray-800 active:bg-gray-50 active:text-gray-800",
                                                  htmlFor: "user-edit__avatar-input"
                                                }, t(undefined, undefined, "change_photo"))), error !== undefined ? React.createElement(School__InputGroupError.make, {
                                                message: error,
                                                active: true
                                              }) : null))), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "user_email",
                                      name: "user_email"
                                    }, t(undefined, undefined, "email_label")), React.createElement("div", {
                                      className: "mt-2 flex items-stretch gap-2"
                                    }, React.createElement("input", {
                                          "aria-label": "User Email",
                                          className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                          id: "user-update__email-input",
                                          disabled: state.disableEmailInput,
                                          name: "user_email",
                                          required: true,
                                          type: "email",
                                          value: state.email,
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateEmail */3,
                                                    _0: $$event.target.value
                                                  });
                                            })
                                        }), state.disableEmailInput ? React.createElement("button", {
                                            className: "btn btn-primary",
                                            disabled: !state.hasCurrentPassword,
                                            onClick: (function (evt) {
                                                Curry._1(send, {
                                                      TAG: /* SetDisableUpdateEmail */4,
                                                      _0: false
                                                    });
                                              })
                                          }, ts(undefined, undefined, "edit")) : React.createElement("div", {
                                            className: "flex gap-2"
                                          }, React.createElement("button", {
                                                className: "btn btn-subtle",
                                                onClick: (function (param) {
                                                    Curry._1(send, {
                                                          TAG: /* UpdateEmailAndDisableInput */18,
                                                          _0: email
                                                        });
                                                  })
                                              }, ts(undefined, undefined, "cancel")), React.createElement("button", {
                                                className: "btn btn-primary",
                                                disabled: EmailUtils.isInvalid(false, state.email) || state.email === email,
                                                onClick: (function (param) {
                                                    Curry._1(send, /* ShowEmailChangePasswordConfirm */4);
                                                  })
                                              }, ts(undefined, undefined, "update")))), ReactUtils.nullIf(React.createElement("p", {
                                          className: "text-yellow-900 text-xs font-inter mt-1"
                                        }, React.createElement(PfIcon.make, {
                                              className: "if i-info-light if-fw"
                                            }), t(undefined, undefined, "update_email_disabled_notice")), state.hasCurrentPassword)))), React.createElement("div", {
                          className: "flex flex-col md:flex-row mt-10 md:mt-12"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "security")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "update_credentials"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, tmp, React.createElement("div", {
                                  id: "passwordContainer"
                                }, React.createElement("h3", {
                                      className: "font-semibold"
                                    }, state.hasCurrentPassword ? t(undefined, undefined, "forgot_password") : t(undefined, undefined, "set_up_password")), React.createElement("p", {
                                      className: "mt-1 text-sm text-gray-600"
                                    }, state.hasCurrentPassword ? t(undefined, undefined, "reset_password_subtext") : t(undefined, undefined, "set_password_subtext")), React.createElement("button", {
                                      className: "bg-primary-100 text-primary-600 border border-primary-200 rounded-md px-4 py-2 mt-2 text-sm font-semibold",
                                      disabled: state.initiatePasswordReset,
                                      onClick: (function (param) {
                                          initiatePasswordReset(send, email);
                                        })
                                    }, state.hasCurrentPassword ? t(undefined, undefined, "reset_password_button_text") : t(undefined, undefined, "set_password_button_text"))))), React.createElement("div", {
                          className: "flex flex-col md:flex-row mt-10 md:mt-12"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "notifications")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "update_email_notifications"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("p", {
                                  className: "font-semibold"
                                }, t(undefined, undefined, "community_digest")), React.createElement("p", {
                                  className: "text-sm text-gray-600"
                                }, t(undefined, undefined, "community_digest_emails")), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("div", {
                                      className: "flex items-center"
                                    }, React.createElement(Radio.make, {
                                          id: "daily_mail_enable",
                                          label: t(undefined, undefined, "send_email_radio"),
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateDailyDigest */12,
                                                    _0: $$event.target.checked
                                                  });
                                            }),
                                          checked: state.dailyDigest
                                        })), React.createElement("div", {
                                      className: "mt-4 flex items-center"
                                    }, React.createElement(Radio.make, {
                                          id: "daily_mail_disable",
                                          label: t(undefined, undefined, "disable_email_radio"),
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateDailyDigest */12,
                                                    _0: !$$event.target.checked
                                                  });
                                            }),
                                          checked: !state.dailyDigest
                                        }))))), React.createElement("div", {
                          className: "flex flex-col md:flex-row mt-10 md:mt-12"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "localization")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "update_locale"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("label", {
                                  className: "font-semibold",
                                  htmlFor: "language"
                                }, t(undefined, undefined, "language")), React.createElement("p", {
                                  className: "text-sm text-gray-600"
                                }, t(undefined, undefined, "select_language")), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("select", {
                                      className: "select appearance-none block text-sm w-full bg-white shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "language",
                                      value: state.locale,
                                      onChange: (function ($$event) {
                                          Curry._1(send, {
                                                TAG: /* UpdateLocale */7,
                                                _0: $$event.target.value
                                              });
                                        })
                                    }, availableLocales.map(function (availableLocale) {
                                          return React.createElement("option", {
                                                      key: availableLocale,
                                                      "aria-selected": state.locale === availableLocale,
                                                      value: availableLocale
                                                    }, Locale.humanize(availableLocale));
                                        })))))), React.createElement("div", {
                      className: "bg-gray-100 px-4 py-5 sm:p-6 flex rounded-b-lg justify-end"
                    }, React.createElement("button", {
                          className: "btn btn-primary",
                          disabled: saveDisabled(state),
                          onClick: (function (param) {
                              return updateUser(state, send, param);
                            })
                        }, t(undefined, undefined, "save_changes")))), React.createElement("div", {
                  className: "bg-white max-w-5xl mx-auto shadow sm:rounded-lg mt-10"
                }, React.createElement("div", {
                      className: "px-4 py-5 sm:p-6"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "appearance_title")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "appearance_description"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("label", {
                                  className: "font-semibold",
                                  htmlFor: "language"
                                }, t(undefined, undefined, "theme_title")), React.createElement("div", {
                                  className: "mt-6 flex flex-col md:flex-row items-start md:grow-0 md:items-center gap-3"
                                }, React.createElement("label", {
                                      className: "w-full md:w-auto p-3 cursor-pointer flex justify-between items-center border border-gray-300 rounded-lg focus-within:outline-none focus-within:border-transparent focus-within:ring-2 focus-within:ring-focusColor-500 ",
                                      htmlFor: "theme-system"
                                    }, React.createElement(Radio.make, {
                                          id: "theme-system",
                                          label: t(undefined, undefined, "system"),
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateThemePreference */13,
                                                    _0: "system"
                                                  });
                                            }),
                                          checked: state.themePreference === "system"
                                        }), React.createElement("div", {
                                          className: "w-16 h-8 flex items-center justify-center p-1 rounded-md border border-gray-200 bg-gray-100"
                                        }, React.createElement(PfIcon.make, {
                                              className: "if i-desktop-monitor-regular if-fw text-lg text-gray-500"
                                            }))), React.createElement("label", {
                                      className: "w-full md:w-auto p-3 cursor-pointer flex justify-between items-center border border-gray-300 rounded-lg focus-within:outline-none focus-within:border-transparent focus-within:ring-2 focus-within:ring-focusColor-500 ",
                                      htmlFor: "theme-light"
                                    }, React.createElement(Radio.make, {
                                          id: "theme-light",
                                          label: t(undefined, undefined, "light"),
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateThemePreference */13,
                                                    _0: "light"
                                                  });
                                            }),
                                          checked: state.themePreference === "light"
                                        }), themeChip("theme-pupilfirst")), React.createElement("label", {
                                      className: "w-full md:w-auto p-3 cursor-pointer flex justify-between items-center border border-gray-300 rounded-lg focus-within:outline-none focus-within:border-transparent focus-within:ring-2 focus-within:ring-focusColor-500 ",
                                      htmlFor: "theme-dark"
                                    }, React.createElement(Radio.make, {
                                          id: "theme-dark",
                                          label: t(undefined, undefined, "dark"),
                                          onChange: (function ($$event) {
                                              Curry._1(send, {
                                                    TAG: /* UpdateThemePreference */13,
                                                    _0: "dark"
                                                  });
                                            }),
                                          checked: state.themePreference === "dark"
                                        }), themeChip("dark"))))))), React.createElement("div", {
                  className: "bg-white max-w-5xl mx-auto shadow sm:rounded-lg mt-10"
                }, React.createElement("div", {
                      className: "px-4 py-5 sm:p-6"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pe-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "account")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "manage_account"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("p", {
                                  className: "font-semibold text-red-700"
                                }, t(undefined, undefined, "delete_account")), React.createElement("p", {
                                  className: "text-sm text-gray-600 mt-1"
                                }, t(undefined, undefined, "deleting_account_warning") + "  "), React.createElement("div", {
                                  className: "mt-4"
                                }, isUndeletableUser || hasValidDeleteAccountToken ? React.createElement("div", {
                                        className: "bg-orange-100 border-s-4 border-orange-400 p-4"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, React.createElement(FaIcon.make, {
                                                classes: "fas fa-exclamation-triangle text-orange-400"
                                              }), React.createElement("div", {
                                                className: "ms-3"
                                              }, React.createElement("p", {
                                                    className: "text-sm text-orange-900"
                                                  }, isUndeletableUser ? t(undefined, undefined, "you_admin_warning") : t(undefined, undefined, "already_iniated_deletion_warning"))))) : React.createElement("button", {
                                        className: "py-2 px-3 border border-red-500 text-red-600 rounded text-xs font-semibold hover:bg-red-600 hover:text-white focus:outline-none active:bg-red-700 active:text-white",
                                        onClick: (function (param) {
                                            Curry._1(send, {
                                                  TAG: /* ChangeDeleteAccountFormVisibility */15,
                                                  _0: true
                                                });
                                          })
                                      }, t(undefined, undefined, "delete_your_account"))))))));
}

var make = UserEdit;

export {
  str ,
  t ,
  ts ,
  reducer ,
  UpdateUserQuery ,
  InitiateAccountDeletionQuery ,
  SendEmailUpdateTokenQuery ,
  InitiatePasswordResetQuery ,
  uploadAvatar ,
  updateEmail ,
  submitAvatarForm ,
  handleAvatarInputChange ,
  initiatePasswordReset ,
  updateUser ,
  initiateAccountDeletion ,
  hasInvalidPassword ,
  saveDisabled ,
  confirmEmailChangeWindow ,
  confirmDeletionWindow ,
  themeChip ,
  make ,
}
/* include Not a pure module */
