// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as User from "../../shared/types/User.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as PfIcon from "../../packages/pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../shared/utils/OptionUtils.bs.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../../shared/Notification.bs.js";
import * as MarkdownBlock from "../../shared/components/MarkdownBlock.bs.js";
import * as DisablingCover from "../../shared/components/DisablingCover.bs.js";
import * as MarkdownEditor from "../../shared/components/MarkdownEditor.bs.js";
import * as TopicsShow__Post from "../types/TopicsShow__Post.bs.js";
import * as TopicsShow__Topic from "../types/TopicsShow__Topic.bs.js";

function str(prim) {
  return prim;
}

import "./TopicsShow__PostEditor.css"
;

var partial_arg = "components.TopicsShow__PostEditor";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreatePostMutation($body: String!, $topicId: ID!, $replyToPostId: ID)  {\ncreatePost(body: $body, topicId: $topicId, replyToPostId: $replyToPostId)  {\npostId  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createPost;
  var value$2 = value$1.postId;
  return {
          createPost: {
            postId: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.createPost;
  var value$2 = value$1.postId;
  var postId = value$2 !== undefined ? value$2 : null;
  var createPost = {
    postId: postId
  };
  return {
          createPost: createPost
        };
}

function serializeVariables(inp) {
  var a = inp.replyToPostId;
  return {
          body: inp.body,
          topicId: inp.topicId,
          replyToPostId: a !== undefined ? a : undefined
        };
}

function makeVariables(body, topicId, replyToPostId, param) {
  return {
          body: body,
          topicId: topicId,
          replyToPostId: replyToPostId
        };
}

var CreatePostQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var CreatePostQuery_Graphql_error = include.Graphql_error;

var CreatePostQuery_decodeNotification = include.decodeNotification;

var CreatePostQuery_decodeObject = include.decodeObject;

var CreatePostQuery_decodeNotifications = include.decodeNotifications;

var CreatePostQuery_decodeErrors = include.decodeErrors;

var CreatePostQuery_flashNotifications = include.flashNotifications;

var CreatePostQuery_sendQuery = include.sendQuery;

var CreatePostQuery_query = include.query;

var CreatePostQuery_make = include.make;

var CreatePostQuery = {
  CreatePostQuery_inner: CreatePostQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreatePostQuery_Graphql_error,
  decodeNotification: CreatePostQuery_decodeNotification,
  decodeObject: CreatePostQuery_decodeObject,
  decodeNotifications: CreatePostQuery_decodeNotifications,
  decodeErrors: CreatePostQuery_decodeErrors,
  flashNotifications: CreatePostQuery_flashNotifications,
  sendQuery: CreatePostQuery_sendQuery,
  query: CreatePostQuery_query,
  $$fetch: $$fetch,
  make: CreatePostQuery_make
};

var Raw$1 = {};

var query$1 = "mutation UpdatePostMutation($id: ID!, $body: String!, $editReason: String)  {\nupdatePost(id: $id, body: $body, editReason: $editReason)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.updatePost;
  return {
          updatePost: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updatePost;
  var value$2 = value$1.success;
  var updatePost = {
    success: value$2
  };
  return {
          updatePost: updatePost
        };
}

function serializeVariables$1(inp) {
  var a = inp.editReason;
  return {
          id: inp.id,
          body: inp.body,
          editReason: a !== undefined ? a : undefined
        };
}

function makeVariables$1(id, body, editReason, param) {
  return {
          id: id,
          body: body,
          editReason: editReason
        };
}

var UpdatePostQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var UpdatePostQuery_Graphql_error = include$1.Graphql_error;

var UpdatePostQuery_decodeNotification = include$1.decodeNotification;

var UpdatePostQuery_decodeObject = include$1.decodeObject;

var UpdatePostQuery_decodeNotifications = include$1.decodeNotifications;

var UpdatePostQuery_decodeErrors = include$1.decodeErrors;

var UpdatePostQuery_flashNotifications = include$1.flashNotifications;

var UpdatePostQuery_sendQuery = include$1.sendQuery;

var UpdatePostQuery_query = include$1.query;

var UpdatePostQuery_make = include$1.make;

var UpdatePostQuery = {
  UpdatePostQuery_inner: UpdatePostQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: UpdatePostQuery_Graphql_error,
  decodeNotification: UpdatePostQuery_decodeNotification,
  decodeObject: UpdatePostQuery_decodeObject,
  decodeNotifications: UpdatePostQuery_decodeNotifications,
  decodeErrors: UpdatePostQuery_decodeErrors,
  flashNotifications: UpdatePostQuery_flashNotifications,
  sendQuery: UpdatePostQuery_sendQuery,
  query: UpdatePostQuery_query,
  $$fetch: $$fetch$1,
  make: UpdatePostQuery_make
};

var dateTime = new Date();

function handlePostCreateResponse(id, body, postNumber, currentUserId, setState, handlePostCB) {
  var post = TopicsShow__Post.make(id, body, currentUserId, undefined, postNumber, dateTime, undefined, 0, false, [], false);
  Curry._1(setState, (function (param) {
          return {
                  body: "",
                  saving: false,
                  editReason: undefined
                };
        }));
  return Curry._1(handlePostCB, post);
}

function handlePostUpdateResponse(id, body, currentUserId, setState, handleCloseCB, handlePostCB, post) {
  var updatedPost = TopicsShow__Post.make(id, body, TopicsShow__Post.creatorId(post), currentUserId, TopicsShow__Post.postNumber(post), TopicsShow__Post.createdAt(post), Caml_option.some(dateTime), TopicsShow__Post.totalLikes(post), TopicsShow__Post.likedByUser(post), TopicsShow__Post.replies(post), TopicsShow__Post.solution(post));
  Curry._1(setState, (function (param) {
          return {
                  body: "",
                  saving: false,
                  editReason: undefined
                };
        }));
  OptionUtils.mapWithDefault((function (cb) {
          Curry._1(cb, undefined);
        }), undefined, handleCloseCB);
  return Curry._1(handlePostCB, updatedPost);
}

function savePost(editReason, body, topic, setState, currentUserId, replyToPostId, handlePostCB, post, postNumber, handleCloseCB, $$event) {
  $$event.preventDefault();
  if (body === "") {
    return $$Notification.error(tr(undefined, undefined, "empty"), tr(undefined, undefined, "cant_blank"));
  }
  Curry._1(setState, (function (state) {
          return {
                  body: state.body,
                  saving: true,
                  editReason: state.editReason
                };
        }));
  if (post !== undefined) {
    var postId = TopicsShow__Post.id(post);
    var variables = {
      id: postId,
      body: body,
      editReason: editReason
    };
    Js_promise.$$catch((function (param) {
            Curry._1(setState, (function (state) {
                    return {
                            body: state.body,
                            saving: false,
                            editReason: state.editReason
                          };
                  }));
            return Promise.resolve(undefined);
          }), Js_promise.then_((function (response) {
                if (response.updatePost.success) {
                  handlePostUpdateResponse(postId, body, currentUserId, setState, handleCloseCB, handlePostCB, post);
                } else {
                  Curry._1(setState, (function (state) {
                          return {
                                  body: state.body,
                                  saving: false,
                                  editReason: state.editReason
                                };
                        }));
                }
                return Promise.resolve(undefined);
              }), Curry._3($$fetch$1, undefined, undefined, variables)));
    return ;
  }
  Js_promise.$$catch((function (param) {
          Curry._1(setState, (function (state) {
                  return {
                          body: state.body,
                          saving: false,
                          editReason: state.editReason
                        };
                }));
          return Promise.resolve(undefined);
        }), Js_promise.then_((function (response) {
              var postId = response.createPost.postId;
              if (postId !== undefined) {
                handlePostCreateResponse(postId, body, postNumber, currentUserId, setState, handlePostCB);
              } else {
                Curry._1(setState, (function (state) {
                        return {
                                body: state.body,
                                saving: false,
                                editReason: state.editReason
                              };
                      }));
              }
              return Promise.resolve(undefined);
            }), Curry._3($$fetch, undefined, undefined, {
                body: body,
                topicId: TopicsShow__Topic.id(topic),
                replyToPostId: replyToPostId
              })));
}

function onBorderAnimationEnd($$event) {
  var element = $$event.target;
  element.className = "w-full flex flex-col";
}

function replyToUserInfo(user) {
  var avatarUrl = Belt_Option.flatMap(user, User.avatarUrl);
  var name = Belt_Option.mapWithDefault(user, "?", User.name);
  return React.createElement("div", {
              className: "flex items-center border bg-white px-2 py-1 rounded-lg"
            }, avatarUrl !== undefined ? React.createElement("img", {
                    className: "w-6 h-6 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover",
                    src: avatarUrl
                  }) : React.createElement(Avatar.make, {
                    name: name,
                    className: "w-6 h-6 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover"
                  }), React.createElement("span", {
                  className: "text-xs font-semibold ms-2"
                }, name));
}

function TopicsShow__PostEditor(Props) {
  var editingOpt = Props.editing;
  var id = Props.id;
  var topic = Props.topic;
  var currentUserId = Props.currentUserId;
  var handlePostCB = Props.handlePostCB;
  var replies = Props.replies;
  var users = Props.users;
  var replyToPostId = Props.replyToPostId;
  var post = Props.post;
  var handleCloseCB = Props.handleCloseCB;
  var removeReplyToPostCB = Props.removeReplyToPostCB;
  var editing = editingOpt !== undefined ? editingOpt : false;
  var match = React.useState(function () {
        return {
                body: post !== undefined ? TopicsShow__Post.body(post) : "",
                saving: false,
                editReason: undefined
              };
      });
  var setState = match[1];
  var state = match[0];
  var updateMarkdownCB = function (body) {
    Curry._1(setState, (function (state) {
            return {
                    body: body,
                    saving: state.saving,
                    editReason: state.editReason
                  };
          }));
  };
  var editReason = state.editReason;
  var setEditReason = function (editReason) {
    Curry._1(setState, (function (state) {
            return {
                    body: state.body,
                    saving: state.saving,
                    editReason: editReason
                  };
          }));
  };
  var newPostNumber = ArrayUtils.isNotEmpty(replies) ? TopicsShow__Post.highestPostNumber(replies) + 1 | 0 : 2;
  var partial_arg = state.body;
  return React.createElement(DisablingCover.make, {
              disabled: state.saving,
              children: React.createElement("div", {
                    "aria-label": "Add new reply",
                    className: "py-2 lg:px-0 max-w-4xl w-full flex mx-auto items-center justify-center relative"
                  }, React.createElement("div", {
                        className: "flex w-full"
                      }, React.createElement("div", {
                            className: "w-full flex flex-col",
                            id: id,
                            onAnimationEnd: onBorderAnimationEnd
                          }, React.createElement("label", {
                                className: "inline-block tracking-wide text-gray-900 text-sm font-semibold mb-2",
                                htmlFor: "new-reply"
                              }, replyToPostId !== undefined ? tr(undefined, undefined, "reply_to") : tr(undefined, undefined, "your_reply")), Belt_Option.mapWithDefault(Belt_Option.flatMap(replyToPostId, (function (postId) {
                                      return Js_array.find((function (reply) {
                                                    return postId === TopicsShow__Post.id(reply);
                                                  }), replies);
                                    })), null, (function (reply) {
                                  return React.createElement("div", {
                                              className: "topics-post-editor__reply-to-preview max-w-md rounded border border-primary-200 p-3 bg-gray-50 mb-3 overflow-hidden"
                                            }, React.createElement("div", {
                                                  className: "flex justify-between"
                                                }, replyToUserInfo(TopicsShow__Post.user(users, reply)), React.createElement("div", {
                                                      className: "flex w-6 h-6 p-2 items-center justify-center cursor-pointer border border-gray-300 rounded bg-gray-50 hover:bg-gray-400",
                                                      onClick: (function (param) {
                                                          OptionUtils.mapWithDefault((function (cb) {
                                                                  Curry._1(cb, undefined);
                                                                }), undefined, removeReplyToPostCB);
                                                        })
                                                    }, React.createElement(PfIcon.make, {
                                                          className: "if i-times-regular text-base"
                                                        }))), React.createElement("p", {
                                                  className: "text-sm pt-2"
                                                }, React.createElement(MarkdownBlock.make, {
                                                      markdown: TopicsShow__Post.body(reply),
                                                      className: "leading-normal text-sm",
                                                      profile: /* Permissive */0
                                                    })), React.createElement("div", {
                                                  className: "topics-post-editor__reply-to-preview-bottom-fadeout"
                                                }));
                                })), React.createElement("div", undefined, React.createElement(MarkdownEditor.make, {
                                    value: state.body,
                                    onChange: updateMarkdownCB,
                                    profile: /* Permissive */0,
                                    textareaId: "new-reply",
                                    maxLength: 10000,
                                    placeholder: tr(undefined, undefined, "type_reply")
                                  }), editing ? React.createElement("input", {
                                      className: "mt-2 appearance-none block w-full bg-white text-gray-900 font-semibold border border-gray-300 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                                      id: "edit-reason",
                                      maxLength: 500,
                                      placeholder: tr(undefined, undefined, "reason_edit"),
                                      value: editReason !== undefined ? editReason : "",
                                      onChange: (function ($$event) {
                                          var reason = $$event.target.value;
                                          if (reason === "") {
                                            return setEditReason(undefined);
                                          } else {
                                            return setEditReason(reason);
                                          }
                                        })
                                    }) : null), React.createElement("div", {
                                className: "flex justify-end pt-3"
                              }, handleCloseCB !== undefined ? React.createElement("button", {
                                      className: "btn btn-subtle me-2",
                                      disabled: state.saving,
                                      onClick: (function (param) {
                                          Curry._1(handleCloseCB, undefined);
                                        })
                                    }, tr(undefined, undefined, "cancel")) : null, React.createElement("button", {
                                    className: "btn btn-primary",
                                    disabled: state.saving || $$String.trim(state.body) === "",
                                    onClick: (function (param) {
                                        return savePost(editReason, partial_arg, topic, setState, currentUserId, replyToPostId, handlePostCB, post, newPostNumber, handleCloseCB, param);
                                      })
                                  }, post !== undefined ? (
                                      TopicsShow__Post.postNumber(post) === 1 ? tr(undefined, undefined, "update_post") : tr(undefined, undefined, "update_reply")
                                    ) : tr(undefined, undefined, "post_reply"))))))
            });
}

var make = TopicsShow__PostEditor;

export {
  str ,
  tr ,
  CreatePostQuery ,
  UpdatePostQuery ,
  dateTime ,
  handlePostCreateResponse ,
  handlePostUpdateResponse ,
  savePost ,
  onBorderAnimationEnd ,
  replyToUserInfo ,
  make ,
}
/*  Not a pure module */
