import Splide from '@splidejs/splide/dist/js/splide.js';
window.Splide = Splide;
import '@splidejs/splide/css';

// Layout-specific JavaScript
import "~/packs/StudentTopNavPack.bs.js";

// Page-specific Styling
import "~/courses/show/show.css";
import "~/courses/apply/apply.css";
import "~/courses/leaderboard/leaderboard.css";
import "~/home/policies.css";
import "~/home/pages.css";
import "~/schools/shared/shared.css";

// Page-specific JavaScript
import "~/psj/home/index";
import "~/psj/users/sessions/new";
import "~/psj/users/dashboard";
import "~/psj/users/delete_account";
import "~/psj/communities/show";
import "~/psj/users/edit";
import "~/psj/topics/show";
import "~/psj/communities/new_topic";
import "~/psj/courses/curriculum";
import "~/psj/courses/report";
import "~/psj/issued_certificates/verify";
import "~/psj/faculty/index";
import "~/psj/users/sessions/reset_password";
import "~/psj/student_app_router";
