// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Caml_array from "../../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__QuizQuestion from "../types/CoursesCurriculum__QuizQuestion.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";

import "./CoursesCurriculum__Quiz.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__Quiz";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation CreateQuizSubmissionMutation($targetId: ID!, $answerIds: [ID!]!)  {\ncreateQuizSubmission(targetId: $targetId, answerIds: $answerIds)  {\nsubmission  {\nid  \ncreatedAt  \nchecklist  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.createQuizSubmission;
  var value$2 = value$1.submission;
  return {
          createQuizSubmission: {
            submission: !(value$2 == null) ? ({
                  id: value$2.id,
                  createdAt: value$2.createdAt,
                  checklist: value$2.checklist
                }) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.createQuizSubmission;
  var value$2 = value$1.submission;
  var submission;
  if (value$2 !== undefined) {
    var value$3 = value$2.checklist;
    var value$4 = value$2.createdAt;
    var value$5 = value$2.id;
    submission = {
      id: value$5,
      createdAt: value$4,
      checklist: value$3
    };
  } else {
    submission = null;
  }
  var createQuizSubmission = {
    submission: submission
  };
  return {
          createQuizSubmission: createQuizSubmission
        };
}

function serializeVariables(inp) {
  var a = inp.answerIds;
  return {
          targetId: inp.targetId,
          answerIds: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function makeVariables(targetId, answerIds, param) {
  return {
          targetId: targetId,
          answerIds: answerIds
        };
}

var CreateQuizSubmissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateQuizSubmissionQuery_Graphql_error = include.Graphql_error;

var CreateQuizSubmissionQuery_decodeNotification = include.decodeNotification;

var CreateQuizSubmissionQuery_decodeObject = include.decodeObject;

var CreateQuizSubmissionQuery_decodeNotifications = include.decodeNotifications;

var CreateQuizSubmissionQuery_decodeErrors = include.decodeErrors;

var CreateQuizSubmissionQuery_flashNotifications = include.flashNotifications;

var CreateQuizSubmissionQuery_sendQuery = include.sendQuery;

var CreateQuizSubmissionQuery_query = include.query;

var CreateQuizSubmissionQuery_fetch = include.$$fetch;

var CreateQuizSubmissionQuery = {
  CreateQuizSubmissionQuery_inner: CreateQuizSubmissionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateQuizSubmissionQuery_Graphql_error,
  decodeNotification: CreateQuizSubmissionQuery_decodeNotification,
  decodeObject: CreateQuizSubmissionQuery_decodeObject,
  decodeNotifications: CreateQuizSubmissionQuery_decodeNotifications,
  decodeErrors: CreateQuizSubmissionQuery_decodeErrors,
  flashNotifications: CreateQuizSubmissionQuery_flashNotifications,
  sendQuery: CreateQuizSubmissionQuery_sendQuery,
  query: CreateQuizSubmissionQuery_query,
  $$fetch: CreateQuizSubmissionQuery_fetch,
  make: make
};

function createQuizSubmission(target, selectedAnswersIds, setSaving, addSubmissionCB) {
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Js_promise.then_((function (response) {
          var submission = response.createQuizSubmission.submission;
          if (submission !== undefined) {
            var submission$1 = Caml_option.valFromOption(submission);
            var partial_arg = [];
            var checklist = Json_decode.array((function (param) {
                    return SubmissionChecklistItem.decode(partial_arg, param);
                  }), submission$1.checklist);
            Curry._1(addSubmissionCB, CoursesCurriculum__Submission.make(submission$1.id, DateFns.decodeISO(submission$1.createdAt), /* MarkedAsComplete */0, checklist));
          } else {
            Curry._1(setSaving, (function (param) {
                    return false;
                  }));
          }
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            targetId: CoursesCurriculum__Target.id(target),
            answerIds: selectedAnswersIds
          }));
}

function answerOptionClasses(answerOption, selectedAnswer) {
  var defaultClasses = "quiz-root__answer bg-white flex items-center shadow border border-transparent rounded p-3 mt-3 cursor-pointer transition ";
  if (selectedAnswer !== undefined && Caml_obj.equal(Caml_option.valFromOption(selectedAnswer), answerOption)) {
    return defaultClasses + "text-primary-500 shadow-md quiz-root__answer-selected ";
  } else {
    return defaultClasses;
  }
}

function iconClasses(answerOption, selectedAnswer) {
  var defaultClasses = "quiz-root__answer-option-icon mb-1 far fa-check-circle text-lg ";
  if (selectedAnswer !== undefined) {
    if (Caml_obj.equal(Caml_option.valFromOption(selectedAnswer), answerOption)) {
      return defaultClasses + "text-primary-500";
    } else {
      return defaultClasses + "text-gray-500";
    }
  } else {
    return defaultClasses + "text-gray-500";
  }
}

function handleSubmit(answer, target, selectedAnswersIds, setSaving, addSubmissionCB, $$event) {
  $$event.preventDefault();
  var answerIds = Js_array.concat(selectedAnswersIds, [CoursesCurriculum__QuizQuestion.answerId(answer)]);
  createQuizSubmission(target, answerIds, setSaving, addSubmissionCB);
}

function CoursesCurriculum__Quiz(Props) {
  var target = Props.target;
  var targetDetails = Props.targetDetails;
  var addSubmissionCB = Props.addSubmissionCB;
  var preview = Props.preview;
  var quizQuestions = CoursesCurriculum__TargetDetails.quizQuestions(targetDetails);
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var match$1 = React.useState(function () {
        return Caml_array.get(quizQuestions, 0);
      });
  var setSelectedQuestion = match$1[1];
  var selectedQuestion = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedAnswer = match$2[1];
  var selectedAnswer = match$2[0];
  var match$3 = React.useState(function () {
        return [];
      });
  var setSelectedAnswersIds = match$3[1];
  var selectedAnswersIds = match$3[0];
  var tmp;
  if (selectedAnswer !== undefined) {
    var tmp$1;
    if (CoursesCurriculum__QuizQuestion.isLastQuestion(quizQuestions, selectedQuestion)) {
      tmp$1 = React.createElement("button", {
            className: "btn btn-primary",
            disabled: match[0] || preview,
            onClick: (function (param) {
                return handleSubmit(selectedAnswer, target, selectedAnswersIds, setSaving, addSubmissionCB, param);
              })
          }, tr(undefined, undefined, "submit_quiz"));
    } else {
      var nextQuestion = CoursesCurriculum__QuizQuestion.nextQuestion(quizQuestions, selectedQuestion);
      tmp$1 = React.createElement("button", {
            className: "btn btn-primary",
            onClick: (function (param) {
                Curry._1(setSelectedQuestion, (function (param) {
                        return nextQuestion;
                      }));
                Curry._1(setSelectedAnswersIds, (function (param) {
                        return Js_array.concat(selectedAnswersIds, [CoursesCurriculum__QuizQuestion.answerId(selectedAnswer)]);
                      }));
                Curry._1(setSelectedAnswer, (function (param) {
                        
                      }));
              })
          }, tr(undefined, undefined, "next_question"));
    }
    tmp = React.createElement("div", {
          className: "quiz-root__answer-submit-section flex justify-center rounded-b-lg text-center p-4 border-t border-gray-200 w-full"
        }, tmp$1);
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: "bg-gray-50 rounded overflow-hidden relative mb-18 mt-4"
            }, React.createElement("div", {
                  className: "p-2 md:p-5"
                }, React.createElement("span", {
                      className: "font-semibold text-xs block uppercase text-gray-600"
                    }, tr(undefined, undefined, "question") + " #", String(CoursesCurriculum__QuizQuestion.index(selectedQuestion) + 1 | 0)), React.createElement(MarkdownBlock.make, {
                      markdown: CoursesCurriculum__QuizQuestion.question(selectedQuestion),
                      className: "text-lg md:text-xl",
                      profile: /* Permissive */0
                    }), React.createElement("div", {
                      className: "pt-2 flex flex-col"
                    }, Js_array.map((function (answerOption) {
                            return React.createElement("button", {
                                        key: CoursesCurriculum__QuizQuestion.answerId(answerOption),
                                        className: answerOptionClasses(answerOption, selectedAnswer),
                                        onClick: (function (param) {
                                            Curry._1(setSelectedAnswer, (function (param) {
                                                    return answerOption;
                                                  }));
                                          })
                                      }, React.createElement(FaIcon.make, {
                                            classes: iconClasses(answerOption, selectedAnswer)
                                          }), React.createElement(MarkdownBlock.make, {
                                            markdown: CoursesCurriculum__QuizQuestion.answerValue(answerOption),
                                            className: "overflow-auto ms-2 w-full",
                                            profile: /* Permissive */0
                                          }));
                          }), CoursesCurriculum__QuizQuestion.answerOptions(selectedQuestion)))), tmp);
}

var make$1 = CoursesCurriculum__Quiz;

export {
  str ,
  tr ,
  CreateQuizSubmissionQuery ,
  createQuizSubmission ,
  answerOptionClasses ,
  iconClasses ,
  handleSubmit ,
  make$1 as make,
}
/*  Not a pure module */
