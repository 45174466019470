// Generated by ReScript, PLEASE EDIT WITH CARE


function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function completed(t) {
  return t.completed;
}

function milestoneNumber(t) {
  return t.milestoneNumber;
}

function make(id, title, completed, milestoneNumber) {
  return {
          id: id,
          title: title,
          milestoneNumber: milestoneNumber,
          completed: completed
        };
}

function makeFromJs(data) {
  return data.map(function (data) {
              return make(data.id, data.title, data.completed, data.milestoneNumber);
            });
}

export {
  id ,
  title ,
  completed ,
  milestoneNumber ,
  make ,
  makeFromJs ,
}
/* No side effect */
