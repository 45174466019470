// Generated by ReScript, PLEASE EDIT WITH CARE


function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function make(id, name, avatarUrl) {
  return {
          id: id,
          name: name,
          avatarUrl: avatarUrl
        };
}

function makeFromJs(jsObject) {
  return {
          id: jsObject.id,
          name: jsObject.name,
          avatarUrl: jsObject.avatarUrl
        };
}

export {
  id ,
  name ,
  avatarUrl ,
  make ,
  makeFromJs ,
}
/* No side effect */
