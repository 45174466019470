// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.$$int, json),
          unlockAt: Json_decode.optional((function (param) {
                  return Json_decode.field("unlockAt", DateFns.decodeISO, param);
                }), json)
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function number(t) {
  return t.number;
}

function unlockAt(t) {
  return t.unlockAt;
}

function isUnlocked(t) {
  var date = t.unlockAt;
  if (date !== undefined) {
    return DateFns.isPast(Caml_option.valFromOption(date));
  } else {
    return true;
  }
}

function isLocked(t) {
  return !isUnlocked(t);
}

function sort(levels) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.number - y.number | 0;
              }), levels);
}

function first(levels) {
  return ArrayUtils.unsafeFind((function (level) {
                return level.number === 1;
              }), "Unable to find level one at CoursesCurriculum__Level", levels);
}

function unlockDateString(t) {
  var unlockAt = t.unlockAt;
  if (unlockAt !== undefined) {
    return DateFns.format(Caml_option.valFromOption(unlockAt), "MMM d");
  } else {
    Rollbar.error("unlockDateString was called for a CoursesCurriculum__Level without unlockAt");
    return "";
  }
}

function findByLevelNumber(levels, levelNumber) {
  return Js_array.find((function (l) {
                return l.number === levelNumber;
              }), levels);
}

function next(levels, t) {
  return findByLevelNumber(levels, t.number + 1 | 0);
}

function previous(levels, t) {
  var previousLevelNumber = t.number - 1 | 0;
  if (previousLevelNumber === 0) {
    return ;
  } else {
    return findByLevelNumber(levels, previousLevelNumber);
  }
}

export {
  decode ,
  id ,
  name ,
  number ,
  unlockAt ,
  isUnlocked ,
  isLocked ,
  sort ,
  first ,
  unlockDateString ,
  findByLevelNumber ,
  next ,
  previous ,
}
/* DateFns Not a pure module */
