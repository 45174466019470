// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "../../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";

function nameOrEmail(t) {
  return t.nameOrEmail;
}

function targetId(t) {
  return t.targetId;
}

function sortCriterion(t) {
  return t.sortCriterion;
}

function sortDirection(t) {
  return t.sortDirection;
}

function tab(t) {
  return t.tab;
}

function includeInactive(t) {
  return t.includeInactive;
}

function assignedCoachId(t) {
  return t.assignedCoachId;
}

function personalCoachId(t) {
  return t.personalCoachId;
}

function reviewingCoachId(t) {
  return t.reviewingCoachId;
}

function defaultDirection(t) {
  var direction = t.sortDirection;
  if (direction !== undefined) {
    return direction;
  }
  var tab = t.tab;
  if (tab === "Pending") {
    return "Ascending";
  } else {
    return "Descending";
  }
}

function makeFromQueryParams(search) {
  var params = new URLSearchParams(search);
  var criterion = params.get("sortCriterion");
  var direction = params.get("sortDirection");
  var t = params.get("tab");
  var t$1 = params.get("includeInactive");
  return {
          nameOrEmail: Caml_option.nullable_to_opt(params.get("search")),
          personalCoachId: Caml_option.nullable_to_opt(params.get("personalCoachId")),
          assignedCoachId: Caml_option.nullable_to_opt(params.get("assignedCoachId")),
          reviewingCoachId: Caml_option.nullable_to_opt(params.get("reviewingCoachId")),
          targetId: Caml_option.nullable_to_opt(params.get("targetId")),
          sortCriterion: !(criterion == null) && criterion === "EvaluatedAt" ? "EvaluatedAt" : "SubmittedAt",
          sortDirection: !(direction == null) ? (
              direction === "Descending" ? "Descending" : (
                  direction === "Ascending" ? "Ascending" : undefined
                )
            ) : undefined,
          tab: !(t == null) ? (
              t === "Pending" ? "Pending" : (
                  t === "Reviewed" ? "Reviewed" : undefined
                )
            ) : undefined,
          includeInactive: (t$1 == null) ? false : t$1 === "true"
        };
}

function toQueryString(filter) {
  var match = filter.sortCriterion;
  var sortCriterion = match === "EvaluatedAt" ? "EvaluatedAt" : "SubmittedAt";
  var filterDict = Js_dict.fromArray([[
          "sortCriterion",
          sortCriterion
        ]]);
  var direction = filter.sortDirection;
  if (direction !== undefined) {
    filterDict["sortDirection"] = direction === "Descending" ? "Descending" : "Ascending";
  }
  Belt_Option.forEach(filter.nameOrEmail, (function (search) {
          filterDict["search"] = search;
        }));
  Belt_Option.forEach(filter.targetId, (function (targetId) {
          filterDict["targetId"] = targetId;
        }));
  Belt_Option.forEach(filter.personalCoachId, (function (coachId) {
          filterDict["personalCoachId"] = coachId;
        }));
  if (Caml_obj.notequal(filter.tab, "Reviewed")) {
    Belt_Option.forEach(filter.assignedCoachId, (function (assignedCoachId) {
            filterDict["assignedCoachId"] = assignedCoachId;
          }));
  }
  if (Caml_obj.notequal(filter.tab, "Pending")) {
    Belt_Option.forEach(filter.reviewingCoachId, (function (reviewingCoachId) {
            filterDict["reviewingCoachId"] = reviewingCoachId;
          }));
  }
  var tab = filter.tab;
  if (tab !== undefined) {
    if (tab === "Reviewed") {
      filterDict["tab"] = "Reviewed";
    } else {
      filterDict["tab"] = "Pending";
    }
  }
  if (filter.includeInactive) {
    filterDict["includeInactive"] = "true";
  }
  return new URLSearchParams(filterDict).toString();
}

export {
  nameOrEmail ,
  targetId ,
  sortCriterion ,
  sortDirection ,
  tab ,
  includeInactive ,
  assignedCoachId ,
  personalCoachId ,
  reviewingCoachId ,
  defaultDirection ,
  makeFromQueryParams ,
  toQueryString ,
}
/* No side effect */
