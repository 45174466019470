// Generated by ReScript, PLEASE EDIT WITH CARE

import AccessEndedSvg from "../images/access-ended.svg";
import CourseEndedSvg from "../images/course-ended.svg";
import PreviewModeSvg from "../images/preview-mode.svg";
import CourseCompleteSvg from "../images/course-complete.svg";

var courseEndedImage = CourseEndedSvg;

var courseCompleteImage = CourseCompleteSvg;

var accessEndedImage = AccessEndedSvg;

var previewModeImage = PreviewModeSvg;

function icon(t) {
  switch (t) {
    case /* Preview */0 :
        return previewModeImage;
    case /* CourseEnded */1 :
        return courseEndedImage;
    case /* CourseComplete */2 :
        return courseCompleteImage;
    case /* AccessEnded */3 :
        return accessEndedImage;
    case /* Nothing */4 :
        return "";
    
  }
}

export {
  courseEndedImage ,
  courseCompleteImage ,
  accessEndedImage ,
  previewModeImage ,
  icon ,
}
/* courseEndedImage Not a pure module */
