// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function name(t) {
  return t.name;
}

function color(t) {
  return t.color;
}

function decode(json) {
  return {
          name: Json_decode.field("name", Json_decode.string, json),
          color: Json_decode.field("color", Json_decode.string, json)
        };
}

export {
  name ,
  color ,
  decode ,
}
/* No side effect */
