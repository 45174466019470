// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesStudents__Submission from "../types/CoursesStudents__Submission.bs.js";
import ReviewedEmptySvg from "../../shared/images/reviewed-empty.svg";

import "./CoursesStudents__StudentOverlay.css"
;

var reviewedEmptyImage = ReviewedEmptySvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesStudents__SubmissionsList";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var Raw = {};

var query = "query StudentSubmissionsQuery($studentId: ID!, $after: String, $sortDirection: SortDirection!)  {\nstudentSubmissions(studentId: $studentId, after: $after, first: 20, sortDirection: $sortDirection)  {\nnodes  {\nid  \ncreatedAt  \npassedAt  \ntitle  \nevaluatedAt  \nmilestoneNumber  \n}\n\npageInfo  {\nhasNextPage  \nendCursor  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.studentSubmissions;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  return {
          studentSubmissions: {
            nodes: Js_array.map((function (value) {
                    var value$1 = value.passedAt;
                    var value$2 = value.evaluatedAt;
                    var value$3 = value.milestoneNumber;
                    return {
                            id: value.id,
                            createdAt: value.createdAt,
                            passedAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                            title: value.title,
                            evaluatedAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                            milestoneNumber: !(value$3 == null) ? value$3 : undefined
                          };
                  }), value$2),
            pageInfo: {
              hasNextPage: value$3.hasNextPage,
              endCursor: !(value$4 == null) ? value$4 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.studentSubmissions;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var endCursor = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.hasNextPage;
  var pageInfo = {
    hasNextPage: value$4,
    endCursor: endCursor
  };
  var value$5 = value$1.nodes;
  var nodes = Js_array.map((function (value) {
          var value$1 = value.milestoneNumber;
          var milestoneNumber = value$1 !== undefined ? value$1 : null;
          var value$2 = value.evaluatedAt;
          var evaluatedAt = value$2 !== undefined ? Caml_option.valFromOption(value$2) : null;
          var value$3 = value.title;
          var value$4 = value.passedAt;
          var passedAt = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
          var value$5 = value.createdAt;
          var value$6 = value.id;
          return {
                  id: value$6,
                  createdAt: value$5,
                  passedAt: passedAt,
                  title: value$3,
                  evaluatedAt: evaluatedAt,
                  milestoneNumber: milestoneNumber
                };
        }), value$5);
  var studentSubmissions = {
    nodes: nodes,
    pageInfo: pageInfo
  };
  return {
          studentSubmissions: studentSubmissions
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  var a$1 = inp.sortDirection;
  return {
          studentId: inp.studentId,
          after: a !== undefined ? a : undefined,
          sortDirection: a$1 === "Descending" ? "Descending" : "Ascending"
        };
}

function makeVariables(studentId, after, sortDirection, param) {
  return {
          studentId: studentId,
          after: after,
          sortDirection: sortDirection
        };
}

var StudentSubmissionsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var StudentSubmissionsQuery_Graphql_error = include.Graphql_error;

var StudentSubmissionsQuery_decodeNotification = include.decodeNotification;

var StudentSubmissionsQuery_decodeObject = include.decodeObject;

var StudentSubmissionsQuery_decodeNotifications = include.decodeNotifications;

var StudentSubmissionsQuery_decodeErrors = include.decodeErrors;

var StudentSubmissionsQuery_flashNotifications = include.flashNotifications;

var StudentSubmissionsQuery_sendQuery = include.sendQuery;

var StudentSubmissionsQuery_query = include.query;

var StudentSubmissionsQuery_fetch = include.$$fetch;

var StudentSubmissionsQuery = {
  StudentSubmissionsQuery_inner: StudentSubmissionsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentSubmissionsQuery_Graphql_error,
  decodeNotification: StudentSubmissionsQuery_decodeNotification,
  decodeObject: StudentSubmissionsQuery_decodeObject,
  decodeNotifications: StudentSubmissionsQuery_decodeNotifications,
  decodeErrors: StudentSubmissionsQuery_decodeErrors,
  flashNotifications: StudentSubmissionsQuery_flashNotifications,
  sendQuery: StudentSubmissionsQuery_sendQuery,
  query: StudentSubmissionsQuery_query,
  $$fetch: StudentSubmissionsQuery_fetch,
  make: make
};

function updateStudentSubmissions(setState, updateSubmissionsCB, endCursor, hasNextPage, submissions, nodes) {
  var updatedSubmissions = Js_array.concat(CoursesStudents__Submission.makeFromJs(nodes), submissions);
  var submissionsData = hasNextPage && endCursor !== undefined ? ({
        TAG: /* PartiallyLoaded */0,
        _0: updatedSubmissions,
        _1: endCursor
      }) : ({
        TAG: /* FullyLoaded */1,
        _0: updatedSubmissions
      });
  Curry._1(updateSubmissionsCB, submissionsData);
  return Curry._1(setState, (function (param) {
                return {
                        loading: false
                      };
              }));
}

function getStudentSubmissions(studentId, cursor, setState, submissions, updateSubmissionsCB) {
  Curry._1(setState, (function (param) {
          return {
                  loading: true
                };
        }));
  Js_promise.then_((function (response) {
          updateStudentSubmissions(setState, updateSubmissionsCB, response.studentSubmissions.pageInfo.endCursor, response.studentSubmissions.pageInfo.hasNextPage, submissions, response.studentSubmissions.nodes);
          return Promise.resolve(undefined);
        }), Curry._3(make, undefined, undefined, {
            studentId: studentId,
            after: cursor,
            sortDirection: "Descending"
          }));
}

function showSubmissionStatus(submission) {
  var _datetime = CoursesStudents__Submission.evaluatedAt(submission);
  if (_datetime !== undefined) {
    if (CoursesStudents__Submission.failed(submission)) {
      return React.createElement("div", {
                  className: "bg-red-100 border border-red-500 shrink-0 leading-normal text-red-800 font-semibold px-3 py-px rounded"
                }, ts(undefined, undefined, "rejected"));
    } else {
      return React.createElement("div", {
                  className: "bg-green-100 border border-green-500 shrink-0 leading-normal text-green-800 font-semibold px-3 py-px rounded"
                }, ts(undefined, undefined, "completed"));
    }
  } else {
    return React.createElement("div", {
                className: "bg-orange-100 border border-orange-300 shrink-0 leading-normal text-orange-600 font-semibold px-3 py-px rounded"
              }, CoursesStudents__Submission.timeDistance(submission));
  }
}

function submissionCardClasses(submission) {
  var _datetime = CoursesStudents__Submission.evaluatedAt(submission);
  return "flex flex-col md:flex-row items-start md:items-center justify-between bg-white border-s-3 p-3 md:py-6 md:px-5 mt-4 cursor-pointer rounded-e-lg shadow hover:border-primary-500 hover:text-primary-500 hover:shadow-md " + (
          _datetime !== undefined ? (
              CoursesStudents__Submission.failed(submission) ? "border-red-500" : "border-green-500"
            ) : "border-orange-400"
        );
}

function showSubmission(submissions) {
  return React.createElement("div", undefined, $$Array.map((function (submission) {
                    return React.createElement("a", {
                                key: CoursesStudents__Submission.id(submission),
                                href: "/submissions/" + (CoursesStudents__Submission.id(submission) + "/review"),
                                target: "_blank"
                              }, React.createElement("div", {
                                    key: CoursesStudents__Submission.id(submission),
                                    "aria-label": "student-submission-card-" + CoursesStudents__Submission.id(submission),
                                    className: submissionCardClasses(submission)
                                  }, React.createElement("div", {
                                        className: "w-full md:w-3/4"
                                      }, React.createElement("div", {
                                            className: "block text-sm md:pe-2"
                                          }, React.createElement("span", {
                                                className: "ms-1 font-semibold text-base"
                                              }, Belt_Option.mapWithDefault(CoursesStudents__Submission.milestoneNumber(submission), "", (function (number) {
                                                      return ts(undefined, undefined, "m") + String(number) + " - ";
                                                    })) + CoursesStudents__Submission.title(submission))), React.createElement("div", {
                                            className: "mt-1 ms-px text-xs text-gray-900"
                                          }, React.createElement("span", {
                                                className: "ms-1"
                                              }, tr(undefined, undefined, "submitted_on") + CoursesStudents__Submission.createdAtPretty(submission)))), React.createElement("div", {
                                        className: "w-auto md:w-1/4 text-xs flex justify-end mt-2 md:mt-0"
                                      }, showSubmissionStatus(submission))));
                  }), CoursesStudents__Submission.sort(submissions)));
}

function showSubmissions(submissions) {
  if (ArrayUtils.isEmpty(submissions)) {
    return React.createElement("div", {
                className: "course-review__reviewed-empty text-lg font-semibold text-center py-4"
              }, React.createElement("h5", {
                    className: "py-4 mt-4 bg-gray-50 text-gray-800 font-semibold"
                  }, tr(undefined, undefined, "no_revied_submission")), React.createElement("img", {
                    className: "w-3/4 md:w-1/2 mx-auto mt-2",
                    src: reviewedEmptyImage
                  }));
  } else {
    return showSubmission(submissions);
  }
}

function CoursesStudents__SubmissionsList(Props) {
  var studentId = Props.studentId;
  var submissions = Props.submissions;
  var updateSubmissionsCB = Props.updateSubmissionsCB;
  var match = React.useState(function () {
        return {
                loading: false
              };
      });
  var setState = match[1];
  React.useEffect((function () {
          if (typeof submissions === "number") {
            getStudentSubmissions(studentId, undefined, setState, [], updateSubmissionsCB);
          }
          
        }), [studentId]);
  var tmp;
  if (typeof submissions === "number") {
    tmp = SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined));
  } else if (submissions.TAG === /* PartiallyLoaded */0) {
    var cursor = submissions._1;
    var submissions$1 = submissions._0;
    tmp = React.createElement("div", undefined, showSubmissions(submissions$1), match[0].loading ? SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined)) : React.createElement("button", {
                className: "btn btn-primary-ghost cursor-pointer w-full mt-4",
                onClick: (function (param) {
                    getStudentSubmissions(studentId, cursor, setState, submissions$1, updateSubmissionsCB);
                  })
              }, ts(undefined, undefined, "load_more")));
  } else {
    tmp = showSubmissions(submissions._0);
  }
  return React.createElement("div", {
              "aria-label": "student-submissions"
            }, tmp);
}

var make$1 = CoursesStudents__SubmissionsList;

export {
  reviewedEmptyImage ,
  str ,
  tr ,
  ts ,
  StudentSubmissionsQuery ,
  updateStudentSubmissions ,
  getStudentSubmissions ,
  showSubmissionStatus ,
  submissionCardClasses ,
  showSubmission ,
  showSubmissions ,
  make$1 as make,
}
/*  Not a pure module */
