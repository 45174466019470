// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as CoursesCurriculum__Notice from "../types/CoursesCurriculum__Notice.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__NoticeManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function showNotice(title, description, notice, classesOpt, param) {
  var classes = classesOpt !== undefined ? classesOpt : "max-w-3xl mx-auto text-center mt-4 bg-white lg:rounded-lg shadow-md px-6 pt-6 pb-8";
  return React.createElement("div", {
              className: classes
            }, React.createElement("img", {
                  className: "h-50 mx-auto",
                  src: CoursesCurriculum__Notice.icon(notice)
                }), React.createElement("div", {
                  className: "max-w-xl font-bold text-xl mx-auto mt-2 leading-tight"
                }, title), React.createElement("div", {
                  className: "text-sm max-w-lg mx-auto mt-2"
                }, description));
}

function courseCompleteMessage(param) {
  return showNotice(t(undefined, undefined, "course_complete_title"), t(undefined, undefined, "course_complete_description"), /* CourseComplete */2, undefined, undefined);
}

function courseEndedMessage(param) {
  return showNotice(t(undefined, undefined, "course_ended_title"), t(undefined, undefined, "course_ended_description"), /* CourseEnded */1, undefined, undefined);
}

function showPreviewMessage(param) {
  return React.createElement("div", {
              className: "flex max-w-lg md:mx-auto mx-3 mt-4 rounded-lg px-3 py-2 shadow-lg items-center border border-primary-300 bg-gray-50 "
            }, React.createElement("img", {
                  className: "w-20 md:w-22 flex-no-shrink",
                  src: CoursesCurriculum__Notice.previewModeImage
                }), React.createElement("div", {
                  className: "flex-1  ms-4"
                }, React.createElement("h4", {
                      className: "font-bold text-lg leading-tight"
                    }, t(undefined, undefined, "preview_mode_title")), React.createElement("p", {
                      className: "text-sm mt-1"
                    }, t(undefined, undefined, "preview_mode_description"))));
}

function accessEndedMessage(param) {
  return showNotice(t(undefined, undefined, "access_ended_title"), t(undefined, undefined, "access_ended_description"), /* AccessEnded */3, undefined, undefined);
}

function CoursesCurriculum__NoticeManager(Props) {
  var notice = Props.notice;
  switch (notice) {
    case /* Preview */0 :
        return showPreviewMessage(undefined);
    case /* CourseEnded */1 :
        return courseEndedMessage(undefined);
    case /* CourseComplete */2 :
        return courseCompleteMessage(undefined);
    case /* AccessEnded */3 :
        return accessEndedMessage(undefined);
    case /* Nothing */4 :
        return null;
    
  }
}

var make = CoursesCurriculum__NoticeManager;

export {
  str ,
  t ,
  showNotice ,
  courseCompleteMessage ,
  courseEndedMessage ,
  showPreviewMessage ,
  accessEndedMessage ,
  make ,
}
/* I18n Not a pure module */
