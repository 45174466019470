// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as Spread from "../../shared/components/Spread.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.accessibility";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function currentLanguage(param) {
  var language = window.document.documentElement.lang;
  switch (language) {
    case "ar" :
        return "English";
    case "en" :
        return "العربية";
    default:
      return "English";
  }
}

function isPersonProfile(param) {
  var element = document.getElementById("person_profile");
  return !(element == null);
}

function handleToggle(setLinksVisible, param) {
  return Curry._1(setLinksVisible, (function (linksVisible) {
                return !linksVisible;
              }));
}

function additionalLinks(linksVisible) {
  if (linksVisible) {
    return React.createElement("div", {
                className: "dropdown__list dropdown__list-right bg-white shadow-lg rounded mt-3 pb-3 border absolute max-w-min z-50"
              }, React.createElement("div", {
                    key: "row_1",
                    className: "flex flex-row justify-center"
                  }, React.createElement("span", {
                        className: "font_size my-auto mx-2 text-gray-900 hover:text-primary-500 hover:bg-gray-50"
                      }, t(undefined, undefined, "font_size")), React.createElement(Spread.make, {
                        props: {
                          "data-action": "click->accessibility#increase"
                        },
                        children: React.createElement("button", {
                              className: "cursor-pointer block p-3 text-xs font-medium text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50"
                            }, "+")
                      }), React.createElement(Spread.make, {
                        props: {
                          "data-action": "click->accessibility#decrease"
                        },
                        children: React.createElement("button", {
                              className: "cursor-pointer block p-3 text-xs font-medium text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50"
                            }, "-")
                      })), React.createElement(Spread.make, {
                    props: {
                      "data-action": "click->accessibility#darkMode"
                    },
                    children: React.createElement("div", {
                          key: "row_2",
                          className: "flex flex-row justify-center"
                        }, React.createElement("p", {
                              className: "font_size my-auto mx-2 text-gray-900 hover:text-primary-500 hover:bg-gray-50",
                              htmlFor: "darkmode"
                            }, t(undefined, undefined, "dark_mode")), React.createElement("button", {
                              className: "cursor-pointer block p-3 text-xs  font-medium text-gray-900 border-b border-gray-50 bg-white hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50",
                              id: "darkmode"
                            }, React.createElement(FaIcon.make, {
                                  classes: "fas fa-sun dark:fa-moon"
                                })))
                  }), isPersonProfile(undefined) ? null : React.createElement(Spread.make, {
                      props: {
                        "data-action": "click->accessibility#toggleLanguage"
                      },
                      children: React.createElement("div", {
                            key: "row_3",
                            className: "flex flex-row justify-center"
                          }, React.createElement("p", {
                                className: "font_size my-auto mx-2 text-gray-900 hover:text-primary-500 hover:bg-gray-50"
                              }, currentLanguage(undefined)))
                    }));
  } else {
    return null;
  }
}

function AccessibilityTopNav__DropDown(Props) {
  var match = React.useState(function () {
        return false;
      });
  var setLinksVisible = match[1];
  return React.createElement(Spread.make, {
              props: {
                "data-controller": "accessibility"
              },
              children: React.createElement("div", {
                    key: "easy_navigation",
                    className: "whitespace-nowrap ms-2 font-medium text-sm p-4 md:px-3 md:py-2 cursor-pointer relative rounded-lg text-gray-900 hover:bg-gray-50 hover:text-primary-500 focus:outline-none focus:bg-gray-50 focus:text-primary-500",
                    title: t(undefined, undefined, "easy_navigation"),
                    onClick: (function (param) {
                        return Curry._1(setLinksVisible, (function (linksVisible) {
                                      return !linksVisible;
                                    }));
                      })
                  }, React.createElement("span", {
                        className: "font_size"
                      }, t(undefined, undefined, "easy_navigation")), React.createElement("i", {
                        className: "if i-eye-solid if-fw mt-2 ms-2"
                      }), additionalLinks(match[0]))
            });
}

var make = AccessibilityTopNav__DropDown;

export {
  str ,
  t ,
  currentLanguage ,
  isPersonProfile ,
  handleToggle ,
  additionalLinks ,
  make ,
}
/* I18n Not a pure module */
