// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";

function value(t) {
  return t.value;
}

function coachName(t) {
  var name = t.coachName;
  if (name !== undefined) {
    return name;
  } else {
    return "Deleted Coach";
  }
}

function createdAt(t) {
  return t.createdAt;
}

function coachAvatarUrl(t) {
  return t.coachAvatarUrl;
}

function coachTitle(t) {
  return t.coachTitle;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function make(coachName, coachAvatarUrl, coachTitle, createdAt, value) {
  return {
          createdAt: createdAt,
          value: value,
          coachName: coachName,
          coachAvatarUrl: coachAvatarUrl,
          coachTitle: coachTitle
        };
}

export {
  value ,
  coachName ,
  createdAt ,
  coachAvatarUrl ,
  coachTitle ,
  createdAtPretty ,
  make ,
}
/* DateFns Not a pure module */
