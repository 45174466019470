// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";

function id(t) {
  return t.id;
}

function title(t) {
  var milestoneNumber = t.milestoneNumber;
  if (milestoneNumber !== undefined) {
    return I18n.t(undefined, undefined, undefined, "shared.m") + String(milestoneNumber) + " - " + t.title;
  } else {
    return t.title;
  }
}

function milestoneNumber(t) {
  return t.milestoneNumber;
}

function make(id, title, milestoneNumber) {
  return {
          id: id,
          title: title,
          milestoneNumber: milestoneNumber
        };
}

function makeFromJs(target) {
  return {
          id: target.id,
          title: target.title,
          milestoneNumber: target.milestoneNumber
        };
}

export {
  id ,
  title ,
  milestoneNumber ,
  make ,
  makeFromJs ,
}
/* I18n Not a pure module */
