// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decodeQuestion(json) {
  return {
          topicId: Json_decode.field("id", Json_decode.string, json),
          topicTitle: Json_decode.field("title", Json_decode.string, json)
        };
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          topics: Json_decode.field("topics", (function (param) {
                  return Json_decode.array(decodeQuestion, param);
                }), json)
        };
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function topics(t) {
  return t.topics;
}

function topicId(topic) {
  return topic.topicId;
}

function topicTitle(question) {
  return question.topicTitle;
}

export {
  decodeQuestion ,
  decode ,
  id ,
  name ,
  topics ,
  topicId ,
  topicTitle ,
}
/* No side effect */
