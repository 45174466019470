// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          id: Caml_option.null_to_opt(Json_decode.field("id", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          title: Json_decode.field("title", Json_decode.string, json)
        };
}

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

export {
  decode ,
  id ,
  title ,
}
/* No side effect */
