// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as User from "../shared/types/User.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as TopicsShow__Post from "../topics/types/TopicsShow__Post.bs.js";
import * as TopicsShow__Root from "../topics/TopicsShow__Root.bs.js";
import * as TopicsShow__Topic from "../topics/types/TopicsShow__Topic.bs.js";
import * as TopicsShow__Community from "../topics/types/TopicsShow__Community.bs.js";
import * as TopicsShow__LinkedTarget from "../topics/types/TopicsShow__LinkedTarget.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("topic", TopicsShow__Topic.decode, json),
          Json_decode.field("firstPost", TopicsShow__Post.decode, json),
          Json_decode.field("replies", (function (param) {
                  return Json_decode.array(TopicsShow__Post.decode, param);
                }), json),
          Json_decode.field("users", (function (param) {
                  return Json_decode.array(User.decode, param);
                }), json),
          Json_decode.field("currentUserId", Json_decode.string, json),
          Json_decode.field("moderator", Json_decode.bool, json),
          Json_decode.field("community", TopicsShow__Community.decode, json),
          Json_decode.optional((function (param) {
                  return Json_decode.field("target", TopicsShow__LinkedTarget.decode, param);
                }), json),
          Json_decode.field("topicCategories", (function (param) {
                  return Json_decode.array(TopicCategory.decode, param);
                }), json),
          Json_decode.field("subscribed", Json_decode.bool, json)
        ];
}

Psj.match(undefined, "topics#show", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(TopicsShow__Root.make, {
                    topic: match[0],
                    firstPost: match[1],
                    replies: match[2],
                    users: match[3],
                    currentUserId: match[4],
                    moderator: match[5],
                    community: match[6],
                    target: match[7],
                    topicCategories: match[8],
                    subscribed: match[9]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
}
/*  Not a pure module */
