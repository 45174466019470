// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function coachId(t) {
  return t.coachId;
}

function submissionId(t) {
  return t.submissionId;
}

function feedback(t) {
  return t.feedback;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          coachId: Json_decode.field("coachId", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          submissionId: Json_decode.field("submissionId", Json_decode.string, json),
          feedback: Json_decode.field("feedback", Json_decode.string, json)
        };
}

export {
  id ,
  coachId ,
  submissionId ,
  feedback ,
  decode ,
}
/* No side effect */
